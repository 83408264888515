import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AxiosError } from "axios";

// Context & Helpers
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";
import { IsActive } from "../../Helpers/UserUtility";
import { SetTitle } from "../../Helpers/Utility";
import { RoutePaths } from "../../Constants/RoutePaths";

// API / Data Fetchers
import { GetVideo, GetVideoPeak } from "../../Api/Video";
import { GetVideoRating } from "../../Api/Rating";
import { GetComments } from "../../Api/VideoComment";

// Components
import FullWatch from "../../Components/UI/Watch/FullWatch";
import FreeWatch from "../../Components/UI/Watch/FreeWatch";

// Models
import IVideoDTO from "../../Models/DTOs/IVideoDTO";
import { IRatingDTO } from "../../Models/DTOs/IRatingDTO";
import ICommentAndPersonDTO from "../../Models/DTOs/ICommentAndPersonDTO";

// A small helper to fetch comments safely
async function GetVideoCommentsSafe(videoId: number): Promise<ICommentAndPersonDTO[]> {
    try {
        const controller = new AbortController();
        const result = await GetComments(videoId, controller);
        if (result instanceof AxiosError) {
            return [];
        }
        return result ?? [];
    } catch {
        return [];
    }
}

function WatchScreen() {
    const authCtx = useContext(UserAuthenticationContext);
    const navigate = useNavigate();
    const { id } = useParams<string>();

    // Local states
    const [videoData, setVideoData] = useState<IVideoDTO | null>(null);
    const [ratingData, setRatingData] = useState<IRatingDTO | undefined>(undefined);
    const [commentsData, setCommentsData] = useState<ICommentAndPersonDTO[]>([]);
    const [peakData, setPeakData] = useState<number>(0);
    const [loadingEssential, setLoadingEssential] = useState<boolean>(true);
    const [loadingRest, setLoadingRest] = useState<boolean>(true);
    const [useFree, setUseFree] = useState<boolean>(false);

    // Generate a session ID for tracking
    const session = uuidv4();

    // Check if user is authenticated
    const isAuth = authCtx.doesAuthTokenExist();

    // Convert param to number
    const videoId = Number(id ?? 0);

    useEffect(() => {
        // Validate videoId
        if (videoId <= 0 || isNaN(videoId)) {
            navigate(RoutePaths.Browse);
            return;
        }

        if (authCtx.userData.SubscriptionStatus === 0) {
            navigate(RoutePaths.ChoosePlan);
        }

        // AbortController to cancel fetches if needed
        const controller = new AbortController();

        // STEP 1: Fetch essential data (the video) first
        async function fetchEssential() {
            setLoadingEssential(true);

            try {
                const videoResult = await GetVideo(
                    videoId,
                    authCtx?.userData?.CurrentCountryCode ?? "GB",
                    controller
                );

                // Validate the response
                if (!videoResult || !("Id" in videoResult) || !videoResult.Id) {
                    navigate(RoutePaths.Browse);
                    return;
                }

                // We have a valid video
                setVideoData(videoResult);
                SetTitle(videoResult.Title ?? "Video");

                setLoadingEssential(false);

                // STEP 2: Fetch other data in parallel
                setLoadingRest(true);

                const [rating, comments, peakResult] = await Promise.all([
                    authCtx.doesAuthTokenExist()
                        ? GetVideoRating(videoId, authCtx.userData.AspNetUserId, controller)
                        : Promise.resolve(undefined),

                    // Comments fetch (safe wrapper)
                    GetVideoCommentsSafe(videoId),

                    authCtx.doesAuthTokenExist()
                        ? GetVideoPeak(authCtx.userData.AspNetUserId, videoId, controller)
                        : Promise.resolve({ Peak: 0 }),
                ]);

                // Update states if the component is still mounted
                if (rating && !(rating instanceof AxiosError)) {
                    setRatingData(rating);
                }
                if (Array.isArray(comments)) {
                    setCommentsData(comments);
                }
                if (peakResult && !(peakResult instanceof AxiosError)) {
                    setPeakData(peakResult.Peak ?? 0);
                } else {
                    setPeakData(0);
                }

                // Decide free watch or full watch
                setUseFree(!isAuth);

            } catch (error) {
                console.error("Error fetching video data:", error);
                //navigate(RoutePaths.Browse);
                return;
            } finally {
                setLoadingRest(false);
            }
        }

        // Trigger the essential fetch
        fetchEssential();

        // Cleanup if unmounted
        return () => {
            controller.abort();
        };
    }, [videoId, isAuth, authCtx.userData, navigate]);

    // If still loading the essential video data, show a skeleton or a loading placeholder.
    if (loadingEssential) {
        return <div>Loading the video...</div>;
    }

    // If no video after trying to load, consider it “not found”
    if (!videoData) {
        return <div>Video not found.</div>;
    }

    // We can render partial UI for the main video now.
    // The “rest” (rating, comments, peak) might still be loading in the background.
    // You could show placeholders for the rating/comments or proceed normally.

    return (
        <div>
            {/* Main Video UI */}
            {useFree ? (
                <FreeWatch
                    videoPromise={Promise.resolve(videoData)}
                    ratingPromise={Promise.resolve(ratingData ?? ({} as IRatingDTO))}
                    commentPromise={Promise.resolve(commentsData)}
                />
            ) : (
                <FullWatch
                    videoPromise={Promise.resolve(videoData)}
                    ratingPromise={Promise.resolve(ratingData ?? ({} as IRatingDTO))}
                    commentPromise={Promise.resolve(commentsData)}
                    peakData={peakData}
                    session={session}
                />
            )}

            {/* (Optional) If you want a separate loading indicator for the rest */}
            {/*{loadingRest && (*/}
            {/*    <div style={{ marginTop: "1rem" }}>*/}
            {/*        Loading additional data (rating, comments, peak)...*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
}

export default WatchScreen;
