import React, { ChangeEvent, forwardRef } from 'react';

interface InputCheckboxProps {
    name: string;
    checked: boolean;
    label: string | React.ReactNode;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    className?: string;
}

const InputCheckbox = forwardRef<HTMLInputElement, InputCheckboxProps>(
    ({ name, checked, label, onChange, required, className }, ref) => {
        return (
            <div className={`form__input-wrapper form__checkbox ${className}`}>
                <input
                    type="checkbox"
                    name={name}
                    checked={checked}
                    onChange={onChange}
                    ref={ref}
                    required={required}
                />
                <span>{label}</span>
            </div>
        );
    }
);

export default InputCheckbox;
