import {useContext, useEffect, useState, useRef} from "react";
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import {NavLink} from "react-router-dom";
import {useStripe, useElements} from '@stripe/react-stripe-js';
import {AxiosError} from "axios";
import {Stripe, StripeElements} from "@stripe/stripe-js";

// Assets
import {IckonicLogo} from "../../Assets/SVGs/Logos";
import IconEye from "../../Assets/SVGs/Icons/Eye";
import IconEyeOff from "../../Assets/SVGs/Icons/EyeOff";
import IconQuestionFilled from "../../Assets/SVGs/Icons/QuestionFilled";

// Models
import {IRegisterDTO} from "../../Models/DTOs/IRegisterDTO.";
import {PlanType} from "../../Models/Enums/PlanType";
import IPlanDTO from "../../Models/DTOs/IPlanDTO";
import {ILoginDTO} from "../../Models/DTOs/ILoginDTO";
import {IProgressStep} from "../../Models/Interfaces/IProgressStep";

// Api
import {LoginAPI, RegisterAPI} from "../../Api/Account";
import EmailFreeViewAPI from "../../Api/EmailFreeView";
import {AddPaymentMethod, CreateSubscription} from "../../Api/Stripe";

// Components
import ButtonPillUppercase from "../../Components/Buttons/ButtonPillUppercase";
import InputField from "../../Components/Inputs/InputField";
import InputCheckbox from "../../Components/Inputs/InputCheckbox";
import InputsStripe from "../../Components/Inputs/InputsStripe";
import PlansSelector from "../../Components/Layouts/PlansSelector";
import Tooltip from "../../Components/ToolTip";
import PopUpBasic from "../../Components/Popups/PopUpBasic";
import ProgressPanel from "../../Components/Popups/ProgressPanel";

// Global state
import {GlobalInterfaceContext} from "../../Context/GlobalInterfaceContext";
import {UserAuthenticationContext} from "../../Context/UserAuthenticationContext";

// Utility functions
import {emailValidation, nameValidation, passwordValidation, setFormError} from "../../utility";
import {RoutePaths} from "../../Constants/RoutePaths";

interface FormState {
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string;
    ConfirmPassword: string;
    CreditCard: string;
    AllowCommercialEmails: boolean;
    TermsOfServiceAccept: boolean;
}

const initialFormState: FormState = {
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
    ConfirmPassword: "",
    CreditCard: "",
    AllowCommercialEmails: true,
    TermsOfServiceAccept: false,
};

type FormKey = keyof FormState;

interface ErrorState {
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string;
    Plans: string;
    ConfirmPassword: string;
    CreditCard: string;
    AllowCommercialEmails: string;
    TermsOfServiceAccept: string;
}

const initialErrorState: ErrorState = {
    FirstName: "valid",
    LastName: "valid",
    Email: "valid",
    Password: "valid",
    Plans: "valid",
    ConfirmPassword: "valid",
    CreditCard: "valid",
    AllowCommercialEmails: "valid",
    TermsOfServiceAccept: "valid",
};

const SignUp = () => {
    const
        /**
         * Local functions / misc vars
         */
        abortController = new AbortController(),
        validationFunctions: { [key: string]: (value: string, t: TFunction) => string } = {
            Email: emailValidation,
            Password: passwordValidation,
            FirstName: nameValidation
            // Add other validation functions here...
        },

        /**
         * Global state
         */
        globalInterfaceContext = useContext(GlobalInterfaceContext),
        userAuthenticationContext = useContext(UserAuthenticationContext),

        /**
         * Hooks
         */
        { t } = useTranslation(),
        stripe = useStripe(),
        elements = useElements(),

        /**
         * Local state
         */
        [formData, setFormData] = useState<FormState>(initialFormState),
        [zipCode, setZipCode] = useState(""),
        [errors, setErrors] = useState<ErrorState>(initialErrorState),
        [selectedPlan, setSelectedPlan] = useState<IPlanDTO | null>(null),
        [isCardComplete, setCardComplete] = useState(false),
        [plansPopUpActive, setPlansPopUpActive] = useState(false),
        [isLoading, setIsLoading] = useState(false),
        [progressPopUpActive, setProgressPopUpActive] = useState(false),
        [showPWValue, setShowPWValue] = useState<boolean>(false),

        // Progress message and steps are used to create a
        // progress bar experience that pops up on form submit.
        // you will see these steps being amended throughout the
        // registration process in the functions below.
        [progressMessage, setProgressMessage] = useState<string>(""),
        [progressSteps, setProgressSteps] = useState<IProgressStep[]>([
            { percent: 25, status: 'pending' },
            { percent: 50, status: 'pending' },
            { percent: 75, status: 'pending' },
            { percent: 100, status: 'pending' }
        ]),

        /**
         * Refs
         */
        emailInputRef = useRef<HTMLInputElement>(null),

        /**
         * Handlers
         */
            // Handles input value state
        handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value, type, checked } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === "checkbox" ? checked : value
            }));
        },

        handlePlanSelect = (plan: IPlanDTO) => {
            console.log(plan);
            setSelectedPlan(plan);
            setFormError(setErrors, 'Plans', "valid");
        },

        // First step in registration process, creates the user account
        registerUser = async (dto: IRegisterDTO): Promise<{ success: boolean; data?: any; error?: string }> => {
            const result = await RegisterAPI(dto, abortController);

            if (result instanceof AxiosError || !result.length) {
                setProgressMessage("Registration failed");
                setProgressSteps(prevSteps => [
                    { percent: 25, status: 'error' },
                    prevSteps[1],
                    prevSteps[2],
                    prevSteps[3]
                ]);
                return { success: false, error: "Registration failed" };
            }

            setProgressSteps(prevSteps => [
                { percent: 25, status: 'success' },
                prevSteps[1],
                prevSteps[2],
                prevSteps[3]
            ]);

            return { success: true, data: result };
        },

        // Second step, logs the user in and retrieves their token,
        // sends token to GetUserDetails to retrieve the AspNetUserId
        loginAndFetchUserData = async (email: string, password: string): Promise<{ success: boolean; data?: string; error?: string }> => {
            setProgressMessage("Embedding Divine Spark");
            try {
                const loginResponse = await LoginAPI(email, password, abortController);
                const tokenData = loginResponse as ILoginDTO;

                if (!tokenData.access_token) {
                    throw new Error("Login failed. Please check your credentials.");
                }

                userAuthenticationContext.storeToken(tokenData.access_token, true);
                const userData = await userAuthenticationContext.fetchUserData(tokenData.access_token);

                if (!userData || !userData.AspNetUserId) {
                    throw new Error("Failed to fetch AspNetUserId");
                }

                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    { percent: 50, status: 'success' },
                    prevSteps[2],
                    prevSteps[3]
                ]);

                return { success: true, data: userData.AspNetUserId };
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
                setProgressMessage(errorMessage);
                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    { percent: 50, status: 'error' },
                    prevSteps[2],
                    prevSteps[3]
                ]);
                return { success: false, error: errorMessage };
            }
        },

        // Third step (part 1), takes card information and creates a payment method in stripe
        createPaymentMethod = async (stripe: Stripe, elements: StripeElements): Promise<{ success: boolean; data?: any; error?: string }> => {
            setProgressMessage("Reserving Passage");
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement('cardNumber')!,
                billing_details: {
                    address: {
                        postal_code: zipCode,
                    },
                }
            });

            if (error) {
                setProgressMessage("Failed confirming payment method");
                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    prevSteps[1],
                    { percent: 75, status: 'error' },
                    prevSteps[3]
                ]);
                return { success: false, error: `Error creating payment method: ${error.message}` };
            }

            return { success: true, data: paymentMethod! };
        },

        // Third step (part 2). Registers payment method with the Ickonic API
        addPaymentMethodToBackend = async (aspNetUserId: string, paymentMethodId: string): Promise<{ success: boolean; error?: string }> => {
            try {
                const response = await AddPaymentMethod(aspNetUserId, paymentMethodId, abortController);
                if (response instanceof AxiosError) {
                    throw new Error("Failed to add payment method");
                }

                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    prevSteps[1],
                    { percent: 75, status: 'success' },
                    prevSteps[3]
                ]);

                return { success: true };
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : "An unexpected error occurred";
                setProgressMessage(errorMessage);
                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    prevSteps[1],
                    { percent: 75, status: 'error' },
                    prevSteps[3]
                ]);
                return { success: false, error: errorMessage };
            }
        },

        // Step five, starts the subscription based on the plan the
        // user selected (using the created payment method in step 4).
        createSubscription = async (aspNetUserId: string, paymentMethodId: string): Promise<{ success: boolean; error?: string }> => {
            setProgressMessage("Entering The Matrix");

            try {
                const response = await CreateSubscription(
                    formData.Email,
                    selectedPlan!.PlanId,
                    aspNetUserId,
                    selectedPlan!.Type as PlanType,
                    paymentMethodId
                );

                // If we reach here, it means the subscription was created successfully
                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    prevSteps[1],
                    prevSteps[2],
                    { percent: 100, status: 'success' }
                ]);

                return { success: true };
            } catch (error) {

                // This will catch both AxiosError and any other errors
                let errorMessage = "Failed to create subscription";

                setProgressMessage("Failed to create subscription");
                setProgressSteps(prevSteps => [
                    prevSteps[0],
                    prevSteps[1],
                    prevSteps[2],
                    { percent: 100, status: 'error' }
                ]);

                return { success: false, error: errorMessage };
            }
        },

        // Runs on form onSubmit
        // - Registers new user account
        // - Logs user in and retrieves AspNetUserId
        // - Creates payment method with provided card info
        // - Saves payment method in Ickonic DB for future use
        // - Creates subscription using selected plan
        // - Displays "Success" message if all steps succeed
        handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            const dto: IRegisterDTO = {
                Id: "unique-id", // Replace this with your logic for generating or getting the ID
                Firstname: formData.FirstName,
                Lastname: formData.LastName,
                Email: formData.Email,
                Password: formData.Password,
                ConfirmPassword: formData.Password,
                AllowCommercialEmails: formData.AllowCommercialEmails
            };

            if (selectedPlan === null || !isCardComplete) {
                setFormError(setErrors, 'Plans', selectedPlan === null ? t("error-plans-message") : "valid");
                setFormError(setErrors, 'CreditCard', !isCardComplete ? t('error-credit-card') : "valid");
                return;
            }

            // Launch the progress panel
            setIsLoading(true);
            setProgressPopUpActive(true);
            setProgressMessage("Constructing Hologram");

            try {
                const registrationResult = await registerUser(dto);
                if (!registrationResult.success) {
                    return;
                }

                const loginResult = await loginAndFetchUserData(formData.Email, formData.Password);
                if (!loginResult.success) {
                    return;
                }
                const aspNetUserId = loginResult.data!;

                if (!stripe || !elements) {
                    setProgressMessage("Payment processor not initialized");
                    setProgressSteps(prevSteps => [
                        prevSteps[0],
                        prevSteps[1],
                        { percent: 75, status: 'error' },
                        prevSteps[3]
                    ]);
                    return;
                }

                const paymentMethodResult = await createPaymentMethod(stripe, elements);
                if (!paymentMethodResult.success) {
                    return;
                }

                const addPaymentResult = await addPaymentMethodToBackend(aspNetUserId, paymentMethodResult.data!.id);
                if (!addPaymentResult.success) {
                    return;
                }

                const subscriptionResult = await createSubscription(aspNetUserId, paymentMethodResult.data!.id);
                if (!subscriptionResult.success) {
                    return;
                }

                setProgressMessage('Success!');
                // Redirect to success page or dashboard

            } catch (error) {
                console.error("Unexpected error:", error);
                // Handle any unexpected errors that weren't caught by the individual functions
            } finally {}
        },

        handleBlur = (inputName: FormKey) => {
            const validate = validationFunctions[inputName];

            if (validate) {
                const validationResult = validate(formData[inputName] as string, t);
                setFormError(setErrors, inputName, validationResult);

                if (inputName === 'Email' && validationResult === 'valid') {
                    // Send to marketing Api if it passes
                    EmailFreeViewAPI(abortController, formData.Email);
                }
            }
        },

        handleCloseProgressPopUp = () => {
            setProgressPopUpActive(false);
            setIsLoading(false);
        };

    useEffect(() => {

        /**
         * Focus email input by default
         */
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }

        return () => {
            abortController.abort();
        };
    }, []);

    return (
        <div className={`page page--sign-up page--no-nav ${globalInterfaceContext?.isDarkMode ? 'is-dark-mode' : 'is-light-mode'}`}>
            {/*<span onClick={() => testProgressPopUp()}>Test progress popup</span>*/}
            <div className={`page__inner ${isLoading ? 'is-loading' : 'is-not-loading'}`}>
                <div className="page__header">
                    <NavLink to='/'>
                        {IckonicLogo()}
                    </NavLink>
                </div>

                <form className="form--sign-up" onSubmit={handleSubmit}>
                    <h1>
                        {t('sign-up-heading')}
                    </h1>

                    <h2>
                        <span>{t('sign-up-sub-heading-line-one')} </span>
                        <span>{t('sign-up-sub-heading-line-two')}</span>
                    </h2>

                    <p className="sign-up__login-switch">
                        Already have an account? <NavLink to={RoutePaths.Login}>Login</NavLink>
                    </p>

                    {/** Email Address **/}
                    <InputField
                        type="email"
                        name="Email"
                        value={formData.Email}
                        label={t('form-label-email')}
                        onChange={handleChange}
                        onFocus={() => {
                        }}
                        onBlur={() => handleBlur("Email")}
                        hasError={errors.Email !== "valid"}
                        required
                        ref={emailInputRef}
                        errorMessage={errors.Email}
                    />

                    {/** First Name **/}
                    <InputField
                        type="text"
                        name="FirstName"
                        value={formData.FirstName}
                        label={t('form-label-name-first')}
                        onChange={handleChange}
                        className="form__name-first"
                        onFocus={() => {
                        }}
                        onBlur={() => handleBlur("FirstName")}
                        required
                        hasError={errors.FirstName !== "valid"}
                        errorMessage={errors.FirstName}
                    />

                    {/** Last Name **/}
                    <InputField
                        type="text"
                        name="LastName"
                        value={formData.LastName}
                        label={t('form-label-name-last')}
                        onChange={handleChange}
                        className="form__name-last"
                        onFocus={() => {
                        }}
                        onBlur={() => handleBlur("LastName")}
                        hasError={errors.LastName !== "valid"}
                        errorMessage={errors.LastName}
                    />

                    {/** Password **/}
                    <div className="form__pw-wrapper">
                        <InputField
                            type={showPWValue ? 'text' : 'password'}
                            name="Password"
                            value={formData.Password}
                            label="Password"
                            onChange={handleChange}
                            onFocus={() => {
                            }}
                            onBlur={() => handleBlur("Password")}
                            required
                            hasError={errors.Password !== "valid"}
                            errorMessage={errors.Password}
                        />

                        <div
                            className="form__pw-show-toggle"
                            onClick={() => setShowPWValue(!showPWValue)}
                        >
                            {showPWValue ? IconEyeOff() : IconEye()}
                        </div>
                    </div>


                    {/** Tier / Plan select **/}
                    <h4 style={{margin: '1rem 0 2rem 0'}}><span>Select a plan</span></h4>

                    <PlansSelector
                        onSelectPlan={(plan) => handlePlanSelect(plan)}
                        parentSelectedPlan={selectedPlan}
                        hasError={errors.Plans !== "valid"}
                        errorMessage={errors.Plans}
                        isDarkMode={globalInterfaceContext !== undefined && globalInterfaceContext?.isDarkMode}
                        featuresStart={2}
                        featuresEnd={5}
                    />

                    <span className="form__popup-trigger" onClick={() => setPlansPopUpActive(true)}>
                        Expand all features
                    </span>

                    <PopUpBasic
                        isOpen={plansPopUpActive}
                        onClose={() => setPlansPopUpActive(false)}
                        padding={globalInterfaceContext?.mainMenuDesktopMode ? "50px 40px 40px 40px" : "30px 20px 20px 20px"}
                        borderRadius="16px"
                        isDarkMode={globalInterfaceContext !== undefined && globalInterfaceContext?.isDarkMode}
                    >
                        <PlansSelector
                            onSelectPlan={(plan) => {
                                handlePlanSelect(plan);
                                setPlansPopUpActive(false);
                            }}
                            parentSelectedPlan={selectedPlan}
                            hasError={errors.Plans !== "valid"}
                            errorMessage={errors.Plans}
                            isDarkMode={globalInterfaceContext !== undefined && globalInterfaceContext?.isDarkMode}
                            featuresStart={0}
                            featuresEnd={10}
                        />
                    </PopUpBasic>

                    {/** "Don't panic" payment information tooltip **/}
                    <h4 style={{margin: '2rem 0 1rem 0'}}>
                        <span>
                            <Tooltip content={t('sign-up-payment-info-tooltip')}>
                                Payment Information {IconQuestionFilled()}
                            </Tooltip>
                        </span>
                    </h4>

                    {/** Stripe Credit Card Form **/}
                    <InputsStripe
                        name="CreditCard"
                        value={formData.CreditCard}
                        label={t('form-label-credit-card')}
                        onFocus={() => {
                        }}
                        onBlur={() => handleBlur("CreditCard")}
                        hasError={errors.CreditCard !== "valid"}
                        errorMessage={errors.CreditCard}
                        required
                        isDarkMode={globalInterfaceContext !== undefined && globalInterfaceContext?.isDarkMode}
                        setCardComplete={setCardComplete}
                        updateZipCode={(newZip) => setZipCode(newZip)}
                    />

                    {/** ToS Accept **/}
                    <InputCheckbox
                        name="TermsOfServiceAccept"
                        checked={formData.TermsOfServiceAccept}
                        onChange={handleChange}
                        label={
                            <>
                                {t('sign-up-terms-of-service-consent')} <NavLink
                                to="/">{t('sign-up-terms-of-service-link-label')}</NavLink>
                            </>
                        }
                        required={true}
                    />

                    {/** Marketing Consent **/}
                    <InputCheckbox
                        name="AllowCommercialEmails"
                        checked={formData.AllowCommercialEmails}
                        onChange={handleChange}
                        label={t('sign-up-marketing-consent')}
                        required={false}
                    />

                    {/** Form submit button **/}
                    <ButtonPillUppercase label="Join the family" className="" link=""/>
                </form>
            </div>

            {/** API fetch status progress **/}
            <ProgressPanel
                isOpen={progressPopUpActive}
                onClose={() => handleCloseProgressPopUp()}
                progressMessage={progressMessage}
                progressSteps={progressSteps}
                isDarkMode={globalInterfaceContext !== undefined && globalInterfaceContext?.isDarkMode}
            />
        </div>
    );
};

export default SignUp;
