export const RoutePaths = {
    /* Root */
    Root: '/',

    /* Create Account */
    CreateYourAccount: "/CreateYourAccount",
    CreateYourAccountTruthSeeker: "/TruthSeeker",
    CreateYourAccountGameChanger: "/GameChanger",

    SignUp: ["/sign-up", "/create-your-account", "/CreateYourAccount"],
    SignUpSuccess: "/sign-up-success",

    /* Subscription Routes */
    ChoosePlan: "/ChoosePlan",
    ConfirmSubscription: "/ConfirmSubscription",
    Renew: "/Renew",
    Renew3DSConfirm: "/Renew3DSConfirm",

    /* Article Routes */
    BrowseArticles: "/Read",
    ArticleCategory: (id : number | ':id') => {return "/Read/Category/" + id},
    ReadArticle: (id: number | string |  ':id') => {return "/Read/r/" + id},
    ReadArticleOld: (id: number | string | ':id') => {return "/news-articles/r/" + id},

    /* Video Player Routes */
    WatchTrailer: (id: number | ':id') => {return "/Watch/t/" + id},
    Watch: (id: number | ':id') => { return "/Watch/" + id },
    SeriesTrailer: (id: number | ':id') => { return "/Series/t/" + id },

    /* Marketing Landing Pages */
    LifetimeMembership: "/lp/lifetime-membership",

    /* Navigation */
    Browse: "/Browse",
    Series: "/Series",
    Films: "/Films",
    SeriesEpisodes: (id: number | ':id') => { return "/Series/" + id},
    News: "/News",
    WeeklyShows: "/WeeklyShows",
    NewsDetails: (id: number | ':id') => {return "/News/" + id},
    Category: (id: number | ':id') => { return "/Category/" + id},
    CategoryFilms: (id: number | ':id') => { return "/Category/" + id + "/Films"},
    Event: (id: number | ':id') => { return "/Event/" + id},
    Affiliate: (link: string | ':link') => { return "/Affiliate/" + link},

    /* Support & TOS */
    HelpCentre: "/HelpCentre",
    TermsOfUse: "/TermsOfUse",
    PrivacyPolicy: "/PrivacyPolicy",
    Careers: "/Careers",

    /* Account */
    Login: "/login",
    Account: "/Account",
    ForgotPassword: "/ForgotPassword",
    ResetPassword: (userId: string | ':userId', code: string | ':code') => { return "/ResetPassword/" + userId + "/" + code},

    /* TV Authentication */
    GenericTvAuth: "TvLogin",
    Roku: "/Roku",
    Firestick: "/Firestick",
    AndroidTV: "/AndroidTV",
    SamsungTV: "/SamsungTV",

    /* Additional Routes */
    Wtaf: "/WTAF",
    RightNow: "/RightNow",
    DotConnector: "/DotConnector",
    IsNowTheTime: "/IsNowTheTime",
    TheDotConnector: "/TheDotConnector",
    DavidIcke: "/DavidIcke",
    DavidIckeFilms: "/DavidIcke/Films"
};

export const ExternalURLs = {
    /* External Resources */
    Shop: "https://shop.ickonic.com/",
    Forum: "https://ickonic-forum.azurewebsites.net/"
}

export const URLsWithoutUI = [
    /^\/sign-up/,
    /^\/create-your-account/,
    /^\/sign-up-success/,
    /^\/login/,
    /^\/CreateYourAccount/,
    /^\/ChoosePlan/,
    /^\/ConfirmSubscription/,
    /^\/lp\/lifetime-membership$/
]

export const URLsWithNotifyBar = [
    /^\/Browse/,
    /^\/Series/,
    /^\/Films/,
    /^\/WeeklyShows/,
    /^\/Read/,
    /^\/DavidIcke/,
]

export function createLoginPath(redirectTo?: string) {
    return "/login" + (redirectTo ?? "");
}
