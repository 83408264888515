import { IVideoPeakDTO } from '../Models/DTOs/IVideoPeakDTO';
import axios, { AxiosError } from "axios";
import IVideoDTO from "../Models/DTOs/IVideoDTO";
import ILatestContentDTO from "../Models/DTOs/ILatestContentDTO";
import { IRelatedVideoInfo } from "../Models/DTOs/IRelatedVideoInfo";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.video;

export async function GetLatestVideo(
    seriesId: number,
    country: string,
    abortController: AbortController
) {
    const video = await axios
        .get(BACKEND_URL + "GetLatestVideo/" + seriesId + "/web/" + country, {
            signal: abortController.signal,
        })
        .then((result) => {
            let seriesResult = result.data as IVideoDTO;
            return seriesResult;
        })
        .catch((error: AxiosError) => {
            console.log("Get latest episode " );
            throw error;
        });

    return video;
}

export async function GetVideo(id: number, countryCode: string, abortController: AbortController) {
    const responce = await axios.get(BACKEND_URL + id + "?",
        {
            signal: abortController.signal,
            params: {
                country: countryCode !== undefined ? countryCode : "GB",
                os: "web"
            }
        }
    ).then((responce) => {
        return responce.data as IVideoDTO;
    }).catch((error: AxiosError) => {
        console.log("Get video error: ");
        throw error;
    });

    return responce;
};

export async function AddVideoStat(videoId: number, userId: string, sessionId: string, peak: number | null, abortController: AbortController) {

    let peakString = peak != null ? "/" + peak : null;

    const responce = await axios.get(BACKEND_URL + "AddStat/" + userId + "/" + videoId + "/" + sessionId + peakString, {signal: abortController.signal}).then((responce) => {
        return responce.data as boolean;
    }).catch((error: AxiosError) => {
        console.log("Stat error");
        return error;
    });

    return responce;
};

export async function ForceWatchVideo(userId: string, sessionId: string, abortController: AbortController) {

    const responce = await axios.post(BACKEND_URL + "ForceWatch/" + userId + "/" + sessionId, null, {signal: abortController.signal}).then((responce) => {
        return responce.data as boolean;
    }).catch((error: AxiosError) => {
        console.log("ForceWatch error" );
        return error;
    });

    return responce;
};

export async function GetVideoPeak(aspNetUserId: string, videoId: number, abortController: AbortController) {
    const responce = await axios.get(BACKEND_URL + "GetPeak/" + aspNetUserId + "/" + videoId, {signal: abortController.signal}).then((responce) => {
        return responce.data as IVideoPeakDTO;
    }).catch((error: AxiosError) => {
        console.log("GetVideoPeak error "+ error);

        return error;
    });

    return responce;
}

export async function GetLatestFour(abortController: AbortController) {
    const numberToGet = 4;
    var response = await axios
        .get(BACKEND_URL + "GetLatestContent/" + numberToGet, { signal: abortController.signal })
        .then((response) => {
            return response.data as ILatestContentDTO[];
        })
        .catch((error: AxiosError) => {
            console.log("GetLatestFour error ");
            return error;
        });

    return response;
}

export async function GetRelatedVideosInfo(videoId: number, countryCode: string, abortController: AbortController) {
    var response = await axios
    .get(BACKEND_URL + "GetRelatedLinks/" + videoId + "/" + countryCode + "/web", { signal: abortController.signal })
    .then((response) => {
        return response.data as IRelatedVideoInfo;
    })
    .catch((error: AxiosError) => {
        console.log(error);
        return null;
    });

    return response;
}
