import React from "react";

// Components
import ButtonPillUppercase from "../../Buttons/ButtonPillUppercase";

// Assets

import {IckonicLogoIcon} from "../../../Assets/SVGs/Logos";
import IconFire from "../../../Assets/SVGs/Icons/Fire";
import IconDiamond from "../../../Assets/SVGs/Icons/Diamond";
import IconTreasureChest from "../../../Assets/SVGs/Icons/TreasureChest";
import ButtonPillGradient from "../../Buttons/ButtonPillGradient";
import {RoutePaths} from "../../../Constants/RoutePaths";

const HeroWithPricingPlans = (props: {
    mainMenuDesktopMode: boolean,
    heading: React.ReactNode,
    tagline?: string,
    backgroundDesktop?: string,
    backgroundMobile?: string,
    planOneTitleBG: string,
    planTwoTitleBG: string,
    planInnerBG: string,
    requireTransition: boolean
}) => {
    return (
        <section className="layout layout--hero-pricing-plans u-relative_hidden">
            <div className="hero__inner">
                {props.heading}

                {props.tagline && (
                    <p className="hero__tagline">
                        {props.tagline}
                    </p>
                )}

                <ul>
                    <li>
                        <div className="hero__icon-wrapper">{IconFire()}</div>
                        <span>Uncensored, unfiltered weekly news shows</span>
                    </li>

                    <li>
                        <div className="hero__icon-wrapper">{IconDiamond()}</div> <span>Exclusive original Ickonic documentaries</span>
                    </li>

                    <li>
                        <div className="hero__icon-wrapper">{IconTreasureChest()}</div> <span>Over 30 years of David Icke content</span>
                    </li>
                </ul>

                <div className="hero__plans">
                    <div className="hero__single-plan">
                        <p style={{background: props.planOneTitleBG}}>
                            Get Started
                        </p>

                        <div className="plan__inner" style={{background: props.planInnerBG}}>
                            <p>
                                Monthly Membership
                            </p>

                            <h2>
                                £1.99 <span>first month</span>
                            </h2>

                            <ul>
                                <li>New Content Every Day</li>
                                <li>Instant Access</li>
                            </ul>

                            <ButtonPillGradient
                                background="linear-gradient(0deg, rgba(187, 54, 184, 1) 0%, #b760b4 100%)"
                                backgroundHover="#b760b4"
                                color="white"
                                text="Select Plan"
                                link={`${RoutePaths.SignUp[0]}?plan=Truth-Seeker`}
                            />
                        </div>
                    </div>

                    <div className="hero__single-plan">
                        <p style={{background: props.planTwoTitleBG}}>
                            Best Value
                        </p>

                        <div className="plan__inner" style={{background: props.planInnerBG}}>
                            <p>
                                Annual Membership
                            </p>

                            <h2>
                                £60 <span>first year</span>
                            </h2>

                            <ul>
                                <li>Save 20% vs Monthly</li>
                                <li>Watch on 2 Devices</li>
                            </ul>

                            <ButtonPillGradient
                                background="linear-gradient(0deg, rgba(187, 54, 184, 1) 0%, #b760b4 100%)"
                                backgroundHover="#b760b4"
                                color="white"
                                text="Select Plan"
                                link={`${RoutePaths.SignUp[0]}?plan=Game-Changer`}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="u-full_cover_absolute hero__background"
                style={{
                    background: props.backgroundDesktop && props.backgroundDesktop ? `url(${props.mainMenuDesktopMode ? props.backgroundDesktop : props.backgroundMobile}) center bottom/cover no-repeat #000000` : 'none',
                }}
            />

            {props.requireTransition && <div className="layout__gradient-transition"/>}
        </section>
    );
};

export default HeroWithPricingPlans;
