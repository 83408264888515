import {NavLink} from "react-router-dom";
import React from "react";

type ButtonGradientProps = {
    link: string,
    text: string,
    icon?: React.ReactNode,
    background?: string,
    backgroundHover?: string,
    color?: string,
    openInNewWindow?: boolean,
    callbackFunction?: () => void,
    className?: string
}

const ButtonPillGradient: React.FC<ButtonGradientProps> = ({
    link = '',
    text = 'Click Here',
    icon,
    background = 'linear-gradient(90deg, rgba(232,187,39) 0%, rgba(229,174,44) 100%)',
    backgroundHover = '',
    color = 'black',
    openInNewWindow = false,
    className = ''
}) => {
    return link === '' ? (
            <button
                className={`ickonic-button button ickonic-button--gradient u-relative_hidden ${className}`}
                style={{
                    color,
                }}
            >
                <div className="ickonic-button__inner">
                    <span>{text}</span>
                    {icon && icon}
                    <div className="u-full_cover_absolute"
                         style={{background: backgroundHover !== "" ? backgroundHover : background}}/>
                    <div className="u-full_cover_absolute" style={{background}}/>
                </div>
            </button>
        )
        : (
            <NavLink
                to={link}
                className={`ickonic-button button ickonic-button--gradient u-relative_hidden ${className}`}
                target={openInNewWindow ? "_blank" : "_self"}
                style={{
                    color,
                }}
            >
                <div className="ickonic-button__inner">
                    <span>{text}</span>
                    {icon && icon}
                    <div className="u-full_cover_absolute"
                         style={{background: backgroundHover !== "" ? backgroundHover : background}}/>
                    <div className="u-full_cover_absolute" style={{background}}/>
                </div>
            </NavLink>
)
};

export default ButtonPillGradient;
