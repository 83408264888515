import React from "react";
import IconAndroid from "../../../Assets/SVGs/Icons/Android";
import IconApple from "../../../Assets/SVGs/Icons/Apple";

// Assets
import Roku from '../../../Assets/Images/FrontPage/roku.png';
import IconSamsung from "../../../Assets/SVGs/Icons/Samsung";
import IconChrome from "../../../Assets/SVGs/Icons/Chrome";
import IconFireTV from "../../../Assets/SVGs/Icons/Samsung";

const CategoryFeaturedItems = () => {
    return (
        <div className="layout--app-grid">
            <h1>
                Many <span>Ways to Watch</span>
            </h1>

            <div className="app-grid__grid">
                <a
                    href="https://play.google.com/store/apps/details?id=com.ickonic.ickonicapp&pli=1"
                    target="_blank"
                    className="app-grid__grid-item"
                >
                    {IconAndroid({iconFill: 'white'})} <span>Android</span>
                </a>

                <a
                    href="https://apps.apple.com/us/app/ickonic/id1551307977"
                    target="_blank"
                    className="app-grid__grid-item"
                >
                    {IconApple({iconFill: 'white'})} <span>Apple</span>
                </a>

                <a
                    href="https://channelstore.roku.com/en-gb/details/863dc8e46592d77817f2247f27790472/ickonic"
                    target="_blank"
                    className="app-grid__grid-item"
                >
                    <img src={Roku} alt="Roku"/>
                </a>

                <a
                    href="https://www.amazon.co.uk/Ickonic-Media-Group-Limited/dp/B0B214MT73/ref=sr_1_2"
                    target="_blank"
                    className="app-grid__grid-item"
                >
                    {IconFireTV()}
                </a>

                <div className="app-grid__grid-item">
                    {IconChrome()} <span>Chromecast</span>
                </div>
            </div>
        </div>
    )
        ;
};

export default CategoryFeaturedItems;
