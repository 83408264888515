import React from "react";

const IconFire = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill }
                  fillRule="evenodd"
                  d="M8.961 1.115a.5.5 0 0 0-.789.214L6.795 5.104l-1.51-1.463a.5.5 0 0 0-.745.056C3.472 5.097 2.5 6.934 2.5 9a5.5 5.5 0 0 0 11 0c0-1.888-.812-3.584-1.765-4.928-.955-1.347-2.08-2.381-2.774-2.957M7.47 6.171l1.392-3.817c.618.563 1.386 1.35 2.057 2.296C11.808 5.904 12.5 7.398 12.5 9a4.5 4.5 0 1 1-9 0c0-1.557.654-3.012 1.503-4.24l1.649 1.599a.5.5 0 0 0 .818-.188m4.023 3.413a.5.5 0 0 0-.986-.168c-.176 1.041-1.05 1.916-2.09 2.091a.5.5 0 1 0 .166.986c1.46-.246 2.663-1.45 2.91-2.91"
                  clipRule="evenodd">
            </path>
        </svg>
    )
};

export default IconFire;
