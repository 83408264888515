const IconAccepted = (props?: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            width="32"
            height="32"
            className={props?.className}
        >
            <circle cx="18" cy="18" r="16" fill="#006400" /> {/* Dark green */}
            <path
                fill="#FFF" // White checkmark
                d="M13 18.5l3.5 3.5 7-7 1.5 1.5-8.5 8.5L11.5 20l1.5-1.5z"
            />
        </svg>
    );
};

export default IconAccepted;
