import { useEffect, useState } from "react";
import ICarouselDTO from "../Models/DTOs/ICarouselDTO";
import { GetCustomCarousels } from "../Api/Carousel"; // Update the path as needed

function useFetchCustomCarousels(countryCode: string, userId: string) {
    const [customCarousels, setCustomCarousels] = useState<ICarouselDTO[] | null>(null);
    const [customCarouselsLoading, setCustomCarouselsLoading] = useState<boolean>(false);
    const [customCarouselsError, setCustomCarouselsError] = useState<string>("");

    useEffect(() => {
        let isCancelled = false;
        setCustomCarouselsLoading(true);

        (async () => {
            try {
                const data = await GetCustomCarousels(countryCode, userId);
                if (!isCancelled) {
                    setCustomCarousels(data);
                }
            } catch (err) {
                if (!isCancelled) {
                    setCustomCarouselsError("Failed to fetch custom carousels.");
                    console.error(err);
                }
            } finally {
                if (!isCancelled) {
                    setCustomCarouselsLoading(false);
                }
            }
        })();

        return () => {
            isCancelled = true;
        };
    }, [countryCode, userId]);

    return { customCarousels, customCarouselsLoading, customCarouselsError };
}

export default useFetchCustomCarousels;
