import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { LocalContextProvider } from "./Store/local-context";
import config from "./Constants/Config"

// Localization
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

// Context
import { UserAuthenticationContextProvider } from "./Context/UserAuthenticationContext";
import { GlobalInterfaceContextProvider } from "./Context/GlobalInterfaceContext";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const stripeKey = config.stripeKey;

const stripePromise = loadStripe(stripeKey!);

root.render(
    <Elements stripe={stripePromise}>

        {/** Multi-language support **/}
        <I18nextProvider i18n={i18n}>

            <UserAuthenticationContextProvider>
                <GlobalInterfaceContextProvider>
                    <LocalContextProvider>
                        <App />
                    </LocalContextProvider>
                </GlobalInterfaceContextProvider>
            </UserAuthenticationContextProvider>

        </I18nextProvider>
    </Elements>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
