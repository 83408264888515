import { useContext } from "react";

// Context
import {UserAuthenticationContext} from "../Context/UserAuthenticationContext";

function AuthScreenRedirect(props: { SubscribedScreen: JSX.Element; FreeScreen: JSX.Element }) {
    const authCtx = useContext(UserAuthenticationContext);
    console.log("AuthScreenRedirect");
    console.log(`Use Sub Screen? ${authCtx.doesAuthTokenExist()}`);
    return authCtx.doesAuthTokenExist() ? props.SubscribedScreen : props.FreeScreen;
}

export default AuthScreenRedirect;
