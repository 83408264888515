import React from "react";

const TripleColumnIcons = () => {
    return (
        <section className="layout layout--triple-column-icons u-relative_hidden">
            <div className="triple-column-icons__inner">
                <div className="triple-column-icons__item">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 40 40"
                    >
                        <path
                            fill="#D9B2FF"
                            fillRule="evenodd"
                            d="M15.934 4.684A5.75 5.75 0 0 1 20 3c2.793 0 5.23 1.987 5.76 4.6a.5.5 0 0 0 .98-.2C26.112 4.307 23.254 2 20 2a6.75 6.75 0 0 0-6.75 6.75v5.75H8.636C7.718 14.5 7 15.263 7 16.172v16.406c0 .909.718 1.672 1.636 1.672h22.728c.918 0 1.636-.763 1.636-1.672V16.172c0-.909-.718-1.672-1.636-1.672H14.25V8.75a5.75 5.75 0 0 1 1.684-4.066M13.75 15.5H8.636c-.337 0-.636.286-.636.672v16.406c0 .386.3.672.636.672h22.728c.337 0 .636-.286.636-.672V16.172c0-.386-.3-.672-.636-.672z"
                            clipRule="evenodd"
                        ></path>
                    </svg>

                    <h2>
                        Uncensored Media
                    </h2>

                    <p>
                        Wide range of alternative media free from mainstream restrictions
                    </p>
                </div>

                <div className="triple-column-icons__item">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 40 40"
                    >
                        <path
                            fill="#D9B2FF"
                            fillRule="evenodd"
                            d="M6.366 9.603C4.136 10.607 3 11.891 3 13.125s1.136 2.518 3.366 3.521c1.053.474 2.307.864 3.704 1.14l.054.01A25.6 25.6 0 0 0 15 18.25a25.6 25.6 0 0 0 4.93-.464c1.397-.276 2.651-.666 3.704-1.14C25.864 15.644 27 14.36 27 13.125s-1.136-2.518-3.366-3.522C21.45 8.621 18.398 8 15 8s-6.45.62-8.634 1.603M19.5 18.882c-1.404.238-2.92.368-4.5.368s-3.096-.13-4.5-.368v5.235c1.388.246 2.906.383 4.5.383a27 27 0 0 0 1.944-.069c.89-.064 1.745-.17 2.556-.314zm1 5.033V18.69c1.316-.283 2.513-.667 3.544-1.13 1.212-.546 2.249-1.23 2.956-2.031v3.847c0 1.234-1.136 2.517-3.366 3.521-.908.409-1.965.754-3.134 1.02m-1 1.217-.084.014.084.02zm1 .235v-.428c1.316-.284 2.513-.667 3.544-1.13C26.337 22.775 28 21.246 28 19.374v-3.709c2.575.291 4.802.946 6.401 1.817 1.732.942 2.593 2.055 2.6 3.13l-.001.012v.028c-.017 1.225-1.152 2.497-3.366 3.493-1.054.474-2.31.864-3.708 1.14l-.045.01c-1.49.29-3.14.454-4.88.454-1.594 0-3.112-.137-4.501-.383m-1 .822a19 19 0 0 1-2.615-.751A28 28 0 0 1 13 25.43v1.445c0 1.234 1.136 2.517 3.366 3.521.908.409 1.965.754 3.134 1.02zm1 5.428v-5.235c1.404.238 2.92.368 4.5.368s3.096-.13 4.5-.368v5.235A26 26 0 0 1 25 32c-1.594 0-3.112-.137-4.5-.383m-11-12.928c-1.316-.283-2.513-.667-3.544-1.13-1.212-.546-2.249-1.23-2.956-2.031v3.847c0 1.234 1.136 2.517 3.367 3.521.907.409 1.964.754 3.133 1.02zm21 12.726V26.19c1.316-.284 2.513-.667 3.544-1.13 1.212-.547 2.249-1.23 2.956-2.032v3.848c0 1.234-1.136 2.517-3.367 3.521-.907.409-1.964.754-3.133 1.02M12 25.34c-2.313-.252-4.392-.788-6.044-1.532C3.663 22.776 2 21.248 2 19.375v-6.25c0-1.873 1.663-3.401 3.956-4.433C8.296 7.638 11.494 7 15 7s6.704.638 9.044 1.692C26.337 9.724 28 11.252 28 13.125v1.535c2.714.295 5.115.984 6.88 1.944 1.834 1 3.12 2.377 3.12 4.02v6.251c0 1.872-1.663 3.401-3.956 4.433C31.704 32.361 28.506 33 25 33s-6.703-.639-9.044-1.692C13.663 30.276 12 28.748 12 26.875z"
                            clipRule="evenodd"
                        ></path>
                    </svg>

                    <h2>
                        Affordable Plans
                    </h2>

                    <p>
                        Entire library for just £1.99 per month, alternative media accessible to all
                    </p>
                </div>

                <div className="triple-column-icons__item">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 40 40"
                    >
                        <path
                            stroke="#D9B2FF"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.548 31.25h22.903a1.25 1.25 0 0 0 1.232-1.032l3.547-16.25a1.25 1.25 0 0 0-2.168-1.036L27.5 20 21.134 5.727a1.25 1.25 0 0 0-2.268 0L12.5 20l-6.563-7.068a1.25 1.25 0 0 0-2.172 1.04l3.547 16.25a1.25 1.25 0 0 0 1.236 1.028"
                        ></path>
                    </svg>

                    <h2>
                        Exclusive Content
                    </h2>

                    <p>
                        Thought-provoking shows and films created exclusively for Ickonic
                    </p>
                </div>
            </div>
        </section>
    );
};

export default TripleColumnIcons;
