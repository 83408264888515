import React from "react";

const IconTreasureChest = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="11"
            fill="none"
            viewBox="0 0 14 11"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                fillRule="evenodd"
                d="M1.732 2.232A2.5 2.5 0 0 1 3 1.55V4.5H1V4a2.5 2.5 0 0 1 .732-1.768M4 4.5v-3h6v3H8.5V4a.5.5 0 0 0-.5-.5H6a.5.5 0 0 0-.5.5v.5zm6 1H8.5V7a.5.5 0 0 1-.5.5H6a.5.5 0 0 1-.5-.5V5.5H4V10h6zm1 4.5V5.5h2V10zm-.5 1H1a1 1 0 0 1-1-1V4A3.5 3.5 0 0 1 3.5.5h7A3.5 3.5 0 0 1 14 4v6a1 1 0 0 1-1 1zm.5-9.45A2.5 2.5 0 0 1 13 4v.5h-2zM1 5.5h2V10H1zM7.5 5v-.5h-1v2h1z"
                clipRule="evenodd"
            ></path>
            <path
                fill={props?.iconFill}
                fillRule="evenodd"
                d="M1.732 2.232A2.5 2.5 0 0 1 3 1.55V4.5H1V4a2.5 2.5 0 0 1 .732-1.768M4 4.5v-3h6v3H8.5V4a.5.5 0 0 0-.5-.5H6a.5.5 0 0 0-.5.5v.5zm6 1H8.5V7a.5.5 0 0 1-.5.5H6a.5.5 0 0 1-.5-.5V5.5H4V10h6zm1 4.5V5.5h2V10zm-.5 1H1a1 1 0 0 1-1-1V4A3.5 3.5 0 0 1 3.5.5h7A3.5 3.5 0 0 1 14 4v6a1 1 0 0 1-1 1zm.5-9.45A2.5 2.5 0 0 1 13 4v.5h-2zM1 5.5h2V10H1zM7.5 5v-.5h-1v2h1z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
};

export default IconTreasureChest;
