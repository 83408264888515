import React from "react";
import { NavLink } from "react-router-dom";
import {format} from "date-fns";

// Helpers
import {GetContentRedirect} from "../../../Helpers/Utility";

// Models
import ICarouselContentDTO, { createPlaceholderICarouselContentDTO } from "../../../Models/DTOs/ICarouselContentDTO";
import {IUserDetails} from "../../../Models/IUserDetails";

// Constants
import {RoutePaths} from "../../../Constants/RoutePaths";

type CarouselLandscapeProps = {
    item: ICarouselContentDTO;
    userData: IUserDetails;
    authTokenExists: boolean;
    itemWidth: number;
    isLoading: boolean;
};

function safeParseDate(dateString: string) {
    // Return null if it's falsy or can't be parsed into a valid Date
    if (!dateString) return null;

    const dateObj = new Date(`${dateString}Z`);
    return isNaN(dateObj.getTime()) ? null : dateObj;
}

function processItem(item: ICarouselContentDTO) {
    const now = new Date();

    // Safely parse dates
    const releaseDateObj = safeParseDate(item.ReleaseDate);
    const earlyAccessDateObj = safeParseDate(item.ReleaseDateEarlyAccess);

    // Determine if not released: either no valid date or date is in the future
    const isNotReleased = !releaseDateObj || releaseDateObj > now;

    // Determine if early access is available: valid date, in the past, and item.Text === "Film"
    const earlyAccessAvailable =
        (!!earlyAccessDateObj && earlyAccessDateObj < now) && (!!releaseDateObj && releaseDateObj > now) && item.Text === 'Film';

    // Decide which date to display
    // - If it’s not released, show empty string
    // - Otherwise, display the earlyAccessDate if available, else the main release date
    const releaseDate = isNotReleased
        ? ''
        : format(earlyAccessDateObj ?? releaseDateObj, 'dd/MM/yyyy hh:mm a');

    return {
        isNotReleased,
        earlyAccessAvailable,
        releaseDate,
    };
}


const CarouselLandscapeItem: React.FC<CarouselLandscapeProps> = (
    {
        item,
        userData,
        authTokenExists,
        itemWidth,
        isLoading
    }
) => {

    // Determine if data is loading; if so, we'll show placeholder items
    const
        redirect = item.IsClickeable ? GetContentRedirect(item.ContentType, item.Id) : null,
        { isNotReleased, earlyAccessAvailable, releaseDate } = processItem(item),
        userHasLifetimeAccessLevel = userData.AccessLevel === 6;

    return (
        <div
            className={`
                carousel-landscape__item u-relative_hidden
                ${earlyAccessAvailable && userHasLifetimeAccessLevel ? 'has-early-access' : 'has-no-early-access'}
            `}
            style={{width: `${itemWidth}px`}}
        >
            {item.IsFreeView && !authTokenExists &&
                <div className="carousel-landscape__item-label free-view-content-item-label"><span>Watch Free</span>
                </div>}

            {earlyAccessAvailable && userHasLifetimeAccessLevel &&
                <div className="carousel-landscape__item-label free-view-content-item-label">
                    <span>Early Access</span></div>}

            {isLoading ?
                (
                    <div className="loader--side-to-side is-sixteen-nine-aspect-ratio"/>
                )
                :
                (
                    <NavLink
                        to={redirect ? redirect : "#"}
                        className="is-sixteen-nine-aspect-ratio">
                        <div
                            className="u-full_cover_absolute"
                            style={{
                                background: `url("${item.Thumbnail ?? ""}") center/cover no-repeat`,
                            }}
                        />
                    </NavLink>
                )
            }

            {isLoading ?
                (
                    <p className="carousel-landscape__item-title"/>
                )
                :
                (
                    <NavLink
                        to={redirect ? redirect : "#"}
                        className="carousel-landscape__item-title">
                        <p>
                            {item.Title}
                        </p>
                    </NavLink>
                )
            }
        </div>
    );
};

export default CarouselLandscapeItem;
