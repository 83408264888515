import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { NavLink } from "react-router-dom";

// Enums
import {ContentType} from "../../../Models/Enums/ContentType";

// Models
import ICarouselContentDTO, { createPlaceholderICarouselContentDTO } from "../../../Models/DTOs/ICarouselContentDTO";

// Components
import ButtonsPrevNext from "../../Buttons/ButtonsPrevNext";
import ButtonPillUppercase from "../../Buttons/ButtonPillUppercase";
import ArrowSmall from "../../../Assets/SVGs/Icons/ArrowSmall";

// Constants
import {RoutePaths} from "../../../Constants/RoutePaths";
import CarouselCardsWithContentItem from "./CarouselCardsWithContentItem";

type CarouselCardsWithContentProps = {
    isDarkMode: boolean;
    heading: React.ReactNode;
    body: React.ReactNode;
    accentColor?: string;
    carouselItems?: ICarouselContentDTO[];
};

const CarouselCardsWithContent: React.FC<CarouselCardsWithContentProps> = ({ isDarkMode, heading, body, accentColor,  carouselItems }) => {
    // Determine if data is loading; if so, we'll show placeholder items
    const
        isLoading = !carouselItems,
        placeholderItems = createPlaceholderICarouselContentDTO(10),

        // Once we have data, use it; otherwise use placeholders
        items = isLoading || carouselItems.length === 0 ? placeholderItems : carouselItems,

        containerRef = useRef<HTMLDivElement>(null),

        [currentIndex, setCurrentIndex] = useState(0),
        [itemsPerView, setItemsPerView] = useState(2),
        [containerWidth, setContainerWidth] = useState(0),

        updateItemsPerView = useCallback(() => {
            const width = window.innerWidth;
            if (width < 400) {
                return 1;
            } else if (width < 760) {
                return 2;
            } else {
                return 3;
            }
        }, []),

        // Compute how many items we actually have
        itemCount = items?.length ?? 0,
        // Calculate maxIndex based on itemCount & itemsPerView
        maxIndex = Math.max(0, itemCount - itemsPerView),

        goToPrev = () => {
            setCurrentIndex((prev) => Math.max(prev - 1, 0));
        },

        goToNext = () => {
            setCurrentIndex((prev) => Math.min(prev + 1, maxIndex));
        },

        handlers = useSwipeable({
            onSwipedLeft: goToNext,
            onSwipedRight: goToPrev,
            trackMouse: true,
        }),

        // Calculate pixel-based layout
        itemWidth = containerWidth && itemsPerView ? containerWidth / itemsPerView : 0,
        trackWidth = itemWidth * itemCount,
        translateX = -currentIndex * itemWidth;

    // On mount & window resize, recalc container width & items per view
    useEffect(() => {
        const handleResize = () => {
            const newItemsPerView = updateItemsPerView();
            setItemsPerView(newItemsPerView);
            if (containerRef.current) {
                setContainerWidth(containerRef.current.clientWidth);
            }
        };

        // Initial measure
        handleResize();

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [updateItemsPerView]);

    // If itemsPerView or items array changes, clamp currentIndex
    useEffect(() => {
        const newMaxIndex = Math.max(0, itemCount - itemsPerView);
        setCurrentIndex((prevIndex) => Math.min(prevIndex, newMaxIndex));
    }, [itemsPerView, itemCount]);

    return (
        <div
            className={`layout--carousel-cards-with-content u-relative_hidden ${
                isDarkMode ? "is-dark-mode" : "has-light-mode"
            }`}
            {...handlers}
        >
            <div className="carousel-cards-with-content__content u-relative_hidden">
                {heading && heading}

                {body && body}
            </div>

            <div className="carousel-cards-with-content__wrapper">
                <div ref={containerRef} className="carousel-cards-with-content__inner">
                    <div
                        className="carousel-cards-with-content__track"
                        style={{
                            width: trackWidth ? `${trackWidth}px` : "auto",
                            transform: `translateX(${translateX}px)`,
                        }}
                    >
                        {items.map((item, i) => (
                            <CarouselCardsWithContentItem
                                item={item}
                                itemWidth={itemWidth}
                                isLoading={isLoading}
                            />
                        ))}
                    </div>
                </div>

                <ButtonsPrevNext
                    onPrev={goToPrev}
                    onNext={goToNext}
                    currentIndex={currentIndex}
                    maxIndex={maxIndex}
                    parentClass="carousel-cards-with-content__controls"
                />
            </div>
        </div>
    );
};

export default CarouselCardsWithContent;
