import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Colours } from "../../../Constants/Colours";
import { Fonts } from "../../../Constants/Fonts";
import { Ease } from "../../../Constants/EasingCurves";

const buttonStyles = `
    box-shadow: inset 0 0 0 calc(2rem/16) ${ Colours.IckonicPink };
    background: none;
    color: ${ Colours.Text } !important;
    font-family: ${ Fonts.Secondary };
    text-decoration: none;
    font-size: 14pt;
    padding: 13px 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 31px;
    text-align: center;
    border: 0;
    line-height: 1;
    margin: 0 auto;
    font-weight: 600;
    transition: background .25s ${ Ease.Smooth }, box-shadow .25s ${ Ease.Smooth }, color .25s ${ Ease.Smooth };
    :disabled {
        opacity: 0.7;
    }

    svg {
        margin: 0 calc(8rem/16) 0 0;
        * {
            fill: white;
            transition: fill .25s ${ Ease.Smooth };
        }
    }

    &:not(:disabled):hover {
        cursor: pointer;
        background: ${ Colours.IckonicPink };
    }
`;

const Button = styled(NavLink)`
    ${ buttonStyles }
`;

function NavLinkPinkButton(props: {children: React.ReactNode, to: string, testId?: string, className?: string }){
    return(
        <Button to={props.to} data-testid={props.testId} className={ props.className !== null ? props.className : '' }>
            {props.children}
        </Button>
    )
}

export default NavLinkPinkButton;
