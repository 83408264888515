import React from "react";

const IconAndroid = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="17"
            fill="none"
            viewBox="0 0 30 17"
        >
            {props?.defs ? props.defs : null}
            <path
                fill={props?.iconFill}
                d="M.5 17q.3-3.567 2.184-6.567T7.7 5.667L5.233 1.4a.72.72 0 0 1-.1-.633.8.8 0 0 1 .434-.5.7.7 0 0 1 .6-.067Q6.5.3 6.7.6l2.467 4.267q2.865-1.2 6-1.2 3.133 0 6 1.2L23.633.6a.96.96 0 0 1 .534-.4.7.7 0 0 1 .6.067.8.8 0 0 1 .433.5.72.72 0 0 1-.1.633l-2.467 4.267q3.134 1.766 5.016 4.766T29.833 17zm8-3.667a1.6 1.6 0 0 0 1.184-.484q.485-.483.483-1.182t-.484-1.184A1.6 1.6 0 0 0 8.5 10a1.62 1.62 0 0 0-1.184.484q-.484.481-.483 1.183.002.7.484 1.184.483.482 1.183.482m13.333 0a1.6 1.6 0 0 0 1.184-.484q.485-.483.483-1.182 0-.699-.484-1.184A1.6 1.6 0 0 0 21.833 10a1.63 1.63 0 0 0-1.184.484q-.483.481-.482 1.183 0 .7.484 1.184.482.482 1.182.482"
            ></path>
        </svg>
    )
};

export default IconAndroid;
