import React from "react";
import { NavLink } from "react-router-dom";

// Models
import ICarouselContentDTO from "../../../Models/DTOs/ICarouselContentDTO";

// Hooks
import useFetchSeriesLatestVideo from "../../../Hooks/useFetchSeriesLatestVideo";

// Helpers
import {GetContentRedirect} from "../../../Helpers/Utility";

type CarouselItemPresenterProps = {
    item: ICarouselContentDTO,
    isLoading: boolean,
    userId: string,
    countryCode: string
};

const CarouselItemPresenter: React.FC<CarouselItemPresenterProps> = ({ item, isLoading, userId, countryCode }) => {
    const
        earlyAccessAvailable = item.ReleaseDateEarlyAccess !== undefined && new Date(`${item.ReleaseDateEarlyAccess}Z`) < new Date() && item.Text === "Film",
        redirect = item.IsClickeable || earlyAccessAvailable ? GetContentRedirect(item.ContentType, item.Id) : null,
        {latestVideo} = useFetchSeriesLatestVideo(item.Link === "#" ? null : item.Id, countryCode);

    return (
        <NavLink to={redirect ? redirect : "#"} className="u-relative_hidden">
            { isLoading ?
                (
                    <div className="loader--side-to-side" />
                )
                :
                (
                    <>
                        <div
                            className="u-full_cover_absolute"
                            style={{
                                background: `url("${item.PortraitThumbnail ?? ""}") center/cover no-repeat`,
                            }}
                        />

                        {/* Show the label (Title) only if it's non-empty */}
                        {latestVideo.Datetime && (
                            <label>
                                <span>
                                    {(() => {
                                        const date = new Date(latestVideo.Datetime); // "2022-03-15T00:00:00"
                                        const day = date.getDate().toString().padStart(2, '0'); // e.g., "15"
                                        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // e.g., "03"
                                        return `Latest Episode: ${day}/${month}`;
                                    })()}
                                </span>
                            </label>
                        )}
                    </>
                )
            }
        </NavLink>
    );
};

export default CarouselItemPresenter;
