import { AxiosError } from "axios";
import { useEffect, useRef, useState, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

import { GetEpisodeBySeason, GetEpisodesByYear } from "../../Api/Carousel";
import { GetIsFavourite } from "../../Api/Favourite";
import { GetSeries } from "../../Api/Series";
import { GetLatestVideo } from "../../Api/Video";
import CarouselItem from "../../Components/Carousels/CarouselItem";
import FeaturedLoader from "../../Components/UI/PageLoaders/FeaturedLoader";
import InfiniteScrollLoader from "../../Components/UI/PageLoaders/InfiniteScrollLoader";
import SeriesHeader from "../../Components/UI/Series/SeriesHeader";
import Heading from "../../Components/UI/Text/Heading";
import { RoutePaths } from "../../Constants/RoutePaths";
import { IsNumber, SetTitle } from "../../Helpers/Utility";
import ICarouselDTO from "../../Models/DTOs/ICarouselDTO";
import ISeriesDTO from "../../Models/DTOs/ISeriesDTO";
import IVideoDTO from "../../Models/DTOs/IVideoDTO";

import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";

const Container = styled.section`
    display: flex;
    flex-direction: column;
    flex: 1;

    .infinite-scroll-component__outerdiv:nth-child(2) {
        padding: 1% 2%;

        h1 {
            font-size: 2.5rem;
            margin: 0;
        }

        .infinite-scroll-component {
            display: flex;
            width: 100%;
            gap: 10px;
            flex-direction: column;
        }
    }
`;

const EpisodeContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    align-self: center;
    width: 100%;

    h1,
    .swiper-initialized {
        grid-column: 1/-1;
    }

    @media only screen and (max-width: 1280px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media only screen and (max-width: 1115px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media only screen and (max-width: 800px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: auto !important;
    }
`;

function SeriesDetailsScreen() {
    const params = useParams();
    const seriesIdString = params.id;
    const navigate = useNavigate();
    const authCtx = useContext(UserAuthenticationContext);
    const controller = new AbortController();

    const [series, setSeries] = useState<ISeriesDTO | null>(null);
    const [latestEpisode, setLatestEpisode] = useState<IVideoDTO | null>(null);
    const [isFavourite, setIsFavourite] = useState<boolean>(false);
    const [episodes, setEpisodes] = useState<{ Carousel: ICarouselDTO; DisplayTitle: boolean }[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [currentYears, setCurrentYears] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const currentSeason = useRef<number>(1);
    const lastId = useRef<number>(0);
    const iteration = useRef<number>(0);
    const skip = useRef<number>(0);
    const isLoaded = useRef<boolean>(false);
    const loadedSeries = useRef<number>(0);

    if (!seriesIdString || !IsNumber(seriesIdString)) {
        navigate(RoutePaths.Browse);
    }
    const seriesId = parseInt(seriesIdString as string);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            const seriesResult = await GetSeries(seriesId, controller);
            if (!seriesResult || seriesResult instanceof AxiosError || !seriesResult.Id || seriesResult.Id <= 0) {
                navigate(RoutePaths.Browse);
                return;
            }
            setSeries(seriesResult);
            SetTitle(seriesResult.Title);

            const latestVideoResult = await GetLatestVideo(seriesId, authCtx.userData.CurrentCountryCode, controller);
            if (latestVideoResult && !(latestVideoResult instanceof AxiosError)) {
                setLatestEpisode(latestVideoResult);
            }

            let fav = false;
            if (authCtx.userData && authCtx.userData.AspNetUserId) {
                const favResult = await GetIsFavourite(seriesId, authCtx.userData.AspNetUserId, true, controller);
                if (!(favResult instanceof AxiosError)) {
                    fav = favResult;
                }
            }
            setIsFavourite(fav);

            // Load initial content
            setEpisodes([]);
            currentSeason.current = 1;
            await GetContent(seriesResult);

            setLoading(false);
        }

        if (!isLoaded.current) {
            fetchData();
            isLoaded.current = true;
        } else if (loadedSeries.current !== seriesId) {
            // If the series changes (unlikely, but just in case)
            fetchData();
        }

        loadedSeries.current = seriesId;
    }, [authCtx.userData, seriesId, navigate]);

    function IsYearlyContentTheSame(result: ICarouselDTO) {
        const contentId = result?.Content[0]?.Id;
        if (lastId.current === contentId) {
            return true;
        }
        return false;
    }

    function SetLastContent(result: ICarouselDTO) {
        lastId.current = result?.Content[0]?.Id;
    }

    async function GetContent(seriesData: ISeriesDTO) {
        console.log('GetContent');

        if (seriesData === null) {
            return;
        }

        setLoading(true);

        if (seriesData.YearlyEpisodeDisplay) {
            console.log('getting episodes by year');
            await EpisodesByYear(seriesData.Id);
        } else {
            console.log('getting episodes by year');
            await EpisodesBySeason(seriesData.Id);
        }

        setLoading(false);
    }

    async function EpisodesBySeason(seriesId: number) {
        const result = await GetEpisodeBySeason(
            seriesId,
            authCtx.userData.CurrentCountryCode,
            controller,
            true,
            currentSeason.current,
            'a'
        );

        if (!result || result instanceof AxiosError || !result.Content || result.Content.length <= 0) {
            setHasMore(false);
            return;
        }

        currentSeason.current += 1;
        setEpisodes((prev) => [...prev, { Carousel: result, DisplayTitle: true }]);
    }

    async function EpisodesByYear(seriesId: number) {
        let take = 15;
        const result = await GetEpisodesByYear(
            seriesId,
            authCtx.userData.CurrentCountryCode,
            authCtx.userData.AspNetUserId,
            take,
            skip.current,
            iteration.current,
            controller,
            'd'
        );

        if (!result || result instanceof AxiosError || !result.Content || result.Content.length <= 0) {
            setHasMore(false);
            return;
        }

        if (IsYearlyContentTheSame(result)) {
            iteration.current += 1;
            await EpisodesByYear(seriesId);
            return;
        }
        SetLastContent(result);

        const year = result.Title.toLowerCase().split("from ")[1].trim();
        let displayTitle = true;

        if (!currentYears.includes(year)) {
            skip.current = result.Content.length;
            setCurrentYears((prev) => [...prev, year]);
        } else {
            skip.current += result.Content.length;
            displayTitle = false;
        }

        if (result.Content.length < take) {
            skip.current = 0;
            iteration.current += 1;
        }

        setEpisodes((prev) => [...prev, { Carousel: result, DisplayTitle: displayTitle }]);
    }

    function DisplayEpisodes(carousel: ICarouselDTO) {
        return carousel.Content.map((content, index) => (
            <CarouselItem key={index.toString() + content.Id} Content={content} />
        ));
    }

    if (loading && episodes.length === 0) {
        return <FeaturedLoader />;
    }

    return (
        <Container>
            {series && (
                <SeriesHeader
                    Content={series}
                    IsFavourite={isFavourite}
                    SetIsFavourite={setIsFavourite}
                    LatestEpisode={latestEpisode?.Id}
                    rating={series.Rating}
                />
            )}

            <InfiniteScroll
                dataLength={episodes.length}
                hasMore={hasMore}
                next={() => series && GetContent(series)}
                loader={<InfiniteScrollLoader />}
            >
                <div>
                    {episodes.map((item, index) => {
                        return (
                            <EpisodeContainer key={index}>
                                {item.DisplayTitle ? (
                                    <Heading>{item.Carousel.Title}</Heading>
                                ) : null}
                                {DisplayEpisodes(item.Carousel)}
                            </EpisodeContainer>
                        );
                    })}
                </div>
            </InfiniteScroll>
        </Container>
    );
}

export default SeriesDetailsScreen;
