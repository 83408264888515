import React, { useEffect, useState, useContext, useCallback } from "react";
import EmailSubscriptionLeadPopup from "./EmailSubscriptionLeadPopup";
import EmailInputList from "../Inputs/EmailInputList";
import styled from "styled-components";
import PinkButton from "../Buttons/PinkButton";
import IconReferAFriend from "../../../Assets/SVGs/Icons/ReferAFriend";
import IconRefused from "../../../Assets/SVGs/Icons/Refused";
import IconAccepted from "../../../Assets/SVGs/Icons/Accepted";
import {
    SubmitCampaignEmails,
    GetCampaignDetailsHeader,
    GetCampaignDetailsByUserID,
} from "../../../Api/Campaign";
import { UserAuthenticationContext } from "../../../Context/UserAuthenticationContext";
import { ICampaignDTO } from "../../../Models/DTOs/ICampaignDTO";

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding: 20px;
    border-radius: 15px;
    background: linear-gradient(to right, #006400 0%, #4b0082 50%, gold 100%);
    color: white;
    width: 100%;
`;

const TransparentPinkButton = styled(PinkButton)`
    box-shadow: none !important;
    border: 2px solid #4b0082 !important;
    background: transparent !important;
    color: white !important;
    border-radius: 999px;
    padding: 10px 25px;
    font-size: 16px;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: #4b0082 !important;
        color: white !important;
        border-color: #4b0082 !important;
    }

    &:focus {
        outline: none !important;
        box-shadow: 0 0 0 4px rgba(75, 0, 130, 0.5);
    }
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const IconWrapper = styled.div`
    svg {
        fill: gold !important;
        stroke: gold !important;
    }
`;

const HighlightedCounter = styled.strong`
    color: gold;
    font-size: 1.2rem;
    font-weight: bold;
    background: #002b28;
    border: 2px solid gold;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const CampaignTitle = styled.span`
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const CardRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
`;

const CardContainer = styled.div`
    background: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
    border: 2px solid #4b0082;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 10px 0;
    overflow: hidden;
    width: 100%;
    color: white;
`;

const CardHeader = styled.div<{ isVisible: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 12px 16px;
    background: rgba(75, 0, 130, 0.5);
    border-bottom: ${({ isVisible }) => (isVisible ? "1px solid gold" : "none")};
    transition: background 0.3s ease, border-bottom 0.3s ease;

    &:hover {
        background: rgba(75, 0, 130, 0.7);
    }

    span {
        color: white;
        font-weight: bold;
    }
`;

const CardTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
    font-size: 0.8rem;
`;

const CardContent = styled.div<{ isVisible: boolean }>`
    max-height: ${({ isVisible }) => (isVisible ? "200px" : "0")};
    overflow-y: ${({ isVisible }) => (isVisible ? "auto" : "hidden")};
    padding: ${({ isVisible }) => (isVisible ? "16px" : "0")};
    transition: max-height 0.3s ease, padding 0.3s ease;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        padding: 4px 0;
        border-bottom: 1px dotted gold;
        font-size: 0.6rem;
        line-height: 1.2;
        color: white;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-child {
            border-bottom: none;
            padding-bottom: 16px;
        }
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: #4b0082;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #7a42cc;
    }

    scrollbar-width: thin;
    scrollbar-color: #4b0082 transparent;
`;

const ReferAFriendModal: React.FC = () => {
    // Replaced old AuthContext with new UserAuthenticationContext
    const authContext = useContext(UserAuthenticationContext);

    const [display, setDisplay] = useState(false);
    const [emails, setEmails] = useState<string[]>([""]);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [referredFriends, setReferredFriends] = useState<string[]>([]);
    const [rejectedFriends, setRejectedFriends] = useState<string[]>([]);
    const [showReferredFriends, setShowReferredFriends] = useState(false);
    const [showRejectedFriends, setShowRejectedFriends] = useState(false);
    const [remainingEmails, setRemainingEmails] = useState<number>(0);
    const [campaignTitle, setCampaignTitle] = useState<string>(
        "Gift the Spirit of Christmas"
    );
    const [campaignThemeId, setCampaignThemeId] = useState<number>(0);
    const [maxEmails, setMaxEmails] = useState<number>(0);
    const [campaignId, setCampaignId] = useState<number | null>(null);
    const [isEmailInputDisabled, setIsEmailInputDisabled] = useState(false);
    const [hasOpenedModal, setHasOpenedModal] = useState(true);

    // Function to reset the state
    const resetState = () => {
        setEmails([""]);
        setIsSubmitEnabled(false);
        setReferredFriends([]);
        setRejectedFriends([]);
        setRemainingEmails(0);
        setIsEmailInputDisabled(false);
        setHasOpenedModal(true);
    };

    // Fetch campaign details and ensure campaignId is retained
    const fetchCampaignIdAndDetails = useCallback(async () => {
        try {
            const userId = authContext.userData?.AspNetUserId;
            if (!userId) throw new Error("User ID is not available");

            // Fetch campaign details
            const campaignDetails = await GetCampaignDetailsHeader();
            setCampaignId(campaignDetails.CampaignID);
            setMaxEmails(campaignDetails.CampaignMaxEmails);
            setCampaignTitle(campaignDetails.CampaignTitle);
            setCampaignThemeId(campaignDetails.CampaignThemeId);

            const userCampaignDetails = await GetCampaignDetailsByUserID(
                userId,
                campaignDetails.CampaignID
            );

            const referredEmails = userCampaignDetails.EmailAddressesAdded.split(",")
                .map((email: string) => email.trim())
                .filter((email: string) => email.length > 0);

            const rejectedEmails = userCampaignDetails.EmailAddressesRefused.split(",")
                .map((email: string) => email.trim())
                .filter((email: string) => email.length > 0);

            setReferredFriends(referredEmails);
            setRejectedFriends(rejectedEmails);

            const calculatedRemainingEmails =
                campaignDetails.CampaignMaxEmails - referredEmails.length;
            setRemainingEmails(calculatedRemainingEmails);

            if (calculatedRemainingEmails <= 0) {
                setIsEmailInputDisabled(true);
            }
        } catch (error) {
            console.error("Failed to fetch campaign details:", error);
            alert("Failed to load campaign details. Please try again later.");
        }
    }, [authContext.userData]);

    // Automatically open cards if there are entries
    useEffect(() => {
        setShowReferredFriends(referredFriends.length > 0);
        setShowRejectedFriends(rejectedFriends.length > 0);
    }, [referredFriends, rejectedFriends]);

    // Open modal and fetch campaign details
    useEffect(() => {
        const handleOpenModal = async () => {
            resetState();
            await fetchCampaignIdAndDetails();
            setDisplay(true);
        };

        document.addEventListener("referAFriend", handleOpenModal);

        return () => {
            document.removeEventListener("referAFriend", handleOpenModal);
        };
    }, [fetchCampaignIdAndDetails]);

    // Handle adding emails
    const handleAddEmail = async () => {
        if (!authContext.userData?.AspNetUserId) {
            alert("User is not authenticated. Please log in.");
            return;
        }

        if (emails.length === 0) {
            alert("Please enter at least one email address before submitting.");
            return;
        }

        if (!campaignId) {
            alert("Campaign ID is missing. Please reload the page.");
            return;
        }

        try {
            const campaignDTO: ICampaignDTO = {
                aspNetUserId: authContext.userData.AspNetUserId,
                campaignId: campaignId.toString(),
                emails,
            };

            const response = await SubmitCampaignEmails(campaignDTO);

            setRemainingEmails(response.remainingEmails);

            // Update referred and rejected friends
            setReferredFriends((prev) => [
                ...prev,
                ...response.emailAddressesAdded.filter((email: string) => !prev.includes(email)),
            ]);
            setRejectedFriends((prev) => [
                ...prev,
                ...response.emailAddressesRefused.filter((email: string) => !prev.includes(email)),
            ]);

            if (response.remainingEmails === 0) {
                // Show "Thank You" message only
                setDisplay(true);
            } else {
                // Close the popup with a "successfully saved" message
                setDisplay(false);
                alert("Emails have been successfully saved!");
            }
        } catch (error) {
            console.error("Failed to submit campaign:", error);
            alert("Failed to submit emails. Please try again later.");
        }
    };

    // Reset `hasOpenedModal` when target is reached
    useEffect(() => {
        if (remainingEmails <= 0) {
            setHasOpenedModal(false);
        }
    }, [remainingEmails]);

    const handleModalClose = () => {
        resetState();
        setEmails([""]);
        setDisplay(false);
    };

    return (
        <EmailSubscriptionLeadPopup display={display} setDisplay={setDisplay}>
            <FormContainer>
                <TitleContainer>
                    <IconWrapper>
                        <IconReferAFriend iconFill="#FF5733" />
                    </IconWrapper>
                    <CampaignTitle>{campaignTitle}</CampaignTitle>
                </TitleContainer>
                {remainingEmails > 0 ? (
                    <>
                        <p>
                            If you add <HighlightedCounter>{remainingEmails}</HighlightedCounter>{" "}
                            {remainingEmails === 1 ? "more email" : " emails"}, and they{" "}
                            {remainingEmails === 1 ? "subscribe" : "all subscribe"} you will earn 12
                            months of free Ickonic access… that’s a whole year!
                        </p>
                        <EmailInputList
                            maxEmails={maxEmails}
                            onEmailsChange={setEmails}
                            onValidEmailChange={setIsSubmitEnabled}
                            disabled={isEmailInputDisabled}
                            remainingEmails={remainingEmails}
                        />
                        <TransparentPinkButton onClick={handleAddEmail} disabled={!isSubmitEnabled}>
                            Send Invitations
                        </TransparentPinkButton>
                        <CardRow>
                            {/* Only show "Accepted Emails" if there are more than 1 entry */}
                            {referredFriends.length >= 1 && (
                                <CardContainer>
                                    <CardHeader
                                        isVisible={showReferredFriends}
                                        onClick={() => setShowReferredFriends((prev) => !prev)}
                                    >
                                        <CardTitle>
                                            <IconAccepted />
                                            <span>Accepted Emails</span>
                                        </CardTitle>
                                        <span>{showReferredFriends ? "▲" : "▼"}</span>
                                    </CardHeader>
                                    <CardContent isVisible={showReferredFriends}>
                                        <ul>
                                            {referredFriends.map((friend, index) => (
                                                <li key={index}>{friend}</li>
                                            ))}
                                        </ul>
                                    </CardContent>
                                </CardContainer>
                            )}

                            {/* Only show "Rejected Emails" if there are more than 1 entry */}
                            {rejectedFriends.length >= 1 && (
                                <CardContainer>
                                    <CardHeader
                                        isVisible={showRejectedFriends}
                                        onClick={() => setShowRejectedFriends((prev) => !prev)}
                                    >
                                        <CardTitle>
                                            <IconRefused />
                                            <span>Rejected Emails</span>
                                        </CardTitle>
                                        <span>{showRejectedFriends ? "▲" : "▼"}</span>
                                    </CardHeader>
                                    <CardContent isVisible={showRejectedFriends}>
                                        <ul>
                                            {rejectedFriends.map((friend, index) => (
                                                <li key={index}>{friend}</li>
                                            ))}
                                        </ul>
                                    </CardContent>
                                </CardContainer>
                            )}
                        </CardRow>
                    </>
                ) : (
                    <div style={{ textAlign: "center", color: "white", padding: "1rem" }}>
                        <h2>Thank You for Sharing the Gift of Ickonic!</h2>
                        <h3>Congratulations,</h3>
                        <p>
                            You did it! You’ve referred 12 family/friends and unlocked the possibility
                            of an entire year of free membership at Ickonic. Your enthusiasm has truly
                            made this holiday season special.
                        </p>
                        <p>We will be in touch soon!</p>
                        <p>Warm regards,</p>
                        <p>
                            <strong>The Ickonic Team</strong>
                        </p>
                    </div>
                )}
                <TransparentPinkButton onClick={handleModalClose}>Close</TransparentPinkButton>
            </FormContainer>
        </EmailSubscriptionLeadPopup>
    );
};

export default ReferAFriendModal;
