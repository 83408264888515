import {useContext, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {RoutePaths, ExternalURLs} from "../../Constants/RoutePaths";
import {useTranslation} from "react-i18next";

// Global state
import { GlobalInterfaceContext } from "../../Context/GlobalInterfaceContext";
import { UserAuthenticationContext } from "../../Context/UserAuthenticationContext";

const HeaderMenu = (props: { isDarkMode: boolean, menuActive: boolean, hasNotificationBar: boolean }) => {
    const
        globalInterfaceContext = useContext(GlobalInterfaceContext),
        userAuthContext = useContext(UserAuthenticationContext),

        {t} = useTranslation(),

        /**
         * Primary used when checking if user clicks outside of menu
         */
        menuRef = useRef<HTMLDivElement>(null),

        /**
         * Uses desktop state if window greater than Xpx wide
         * consider converting hardcoded width value to global var
         */
        calculateWindowWidth = () => {
            globalInterfaceContext?.toggleMainMenuDesktopMode(window.innerWidth >= 1100);
            globalInterfaceContext?.toggleMainMenuOpenState(false);
        },

        watchSubMenu = <ul className="sub-menu">
            {/*<li><Link to={RoutePaths.WeeklyShows} className="menu__item">{t('main-menu-item-weekly-shows')}</Link></li>*/}
            <li><Link to={RoutePaths.Series} className="menu__item">{t('main-menu-item-series')}</Link></li>
            <li><Link to={RoutePaths.Films} className="menu__item">{t('main-menu-item-films')}</Link></li>
        </ul>,

        /**
         * Main menu items
         */
        menuMain = <ul onClick={() => globalInterfaceContext?.toggleMainMenuOpenState(false)}>
            <li><Link to={RoutePaths.Browse} className="menu__item">{t('main-menu-item-browse')}</Link>{watchSubMenu}</li>
            <li><Link to={RoutePaths.BrowseArticles} className="menu__item">{t('main-menu-item-read')}</Link></li>
            <li><Link to={RoutePaths.DavidIcke} className="menu__item">{t('main-menu-item-david-icke')}</Link></li>
            <li><a href={ExternalURLs.Shop} target="_blank" rel="noreferrer" className="menu__item">{t('main-menu-item-shop')}</a></li>
            {/*<li><a href={ExternalURLs.Forum} target="_blank" rel="noreferrer" className="menu__item">{t('main-menu-item-forums')}</a></li>*/}
        </ul>,

        /**
         * Closes the slide out menu when the user
         * clicks somewhere other than the menu ui
         * **/
        handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Node;

            /**
             * When the user clicks the "hamburger" menu to open the sidebar
             * slide out, since it is not contained within the side out UI itself,
             * without this exception, it will prevent the menu from opening.
             * **/
            const isClickInsideToggleButton = (target: Node): boolean => {
                let currentNode: Node | null = target;
                while (currentNode && currentNode !== document.body) {
                    if (currentNode instanceof Element && currentNode.classList.contains('button--burger')) {
                        return true;
                    }
                    currentNode = currentNode.parentNode;
                }
                return false;
            };

            /**
             * Check if user clicked outside of menu
             */
            if (menuRef.current && !menuRef.current.contains(target) && !isClickInsideToggleButton(target)) {
                globalInterfaceContext?.toggleMainMenuOpenState(false);
            }
        };

    /** Runs when component mounts */
    useEffect(() => {

        /**
         * - Looks to see if the window is wide enough to reach the
         * desktop breakpoint.
         *
         * - Closes the menu (UX thing to prevent any re-size issues)
         * **/
        calculateWindowWidth();

        /**
         * - Close menu when user clicks off of it
         * - Check resize values when the window is manually resized
         * **/
        document.addEventListener('click', handleClickOutside);
        window.addEventListener('resize', calculateWindowWidth);
    }, []);

    return (
        <nav
            ref={ menuRef }
            className={
                `menu menu--header
            ${props.isDarkMode ? 'is-dark-mode' : 'has-light-mode'}
            ${props.menuActive ? 'menu-is-active' : 'menu-inactive'}
            ${userAuthContext && userAuthContext.userData.AspNetUserId ? 'user-is-authenticated' : 'user-not-authenticated'}`}
        >
            { /** Horizontal Desktop Menu **/ }
            { globalInterfaceContext?.mainMenuDesktopMode ? (
                <div className="menu__horizontal">
                    { menuMain }
                </div>
            ) : null }

            { /** Mobile / Overflow Slide-out Menu **/ }
            <div className={
                `menu__slideout
                ${userAuthContext && userAuthContext.userData.AspNetUserId ? 'user-is-authenticated' : 'user-not-authenticated'}
                ${globalInterfaceContext?.mainMenuDesktopMode ? 'using-desktop-mode' : 'using-mobile-mode'}
                ${props.hasNotificationBar ? 'has-notification-bar' : 'notification-bar-hidden'}
                ${props.isDarkMode ? 'is-dark-mode' : 'has-light-mode'}` }>

                <div className={
                    `menu__inner u-relative_hidden
                    ${ globalInterfaceContext?.mainMenuOpen ? 'menu-is-open' : 'menu-is-closed' }` }>

                    { /** Displays main menu when NOT in 'desktop mode' **/ }
                    { !globalInterfaceContext?.mainMenuDesktopMode ? menuMain : null }
                </div>
            </div>
        </nav>
    );
};

export default HeaderMenu;
