import React, { useState, FocusEvent, ChangeEvent, forwardRef } from 'react';

interface InputFieldProps {
    type: string;
    name: string;
    value: string;
    label: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onFocus: (name: string) => void;
    onBlur: (name: string) => void;
    hasError: boolean;
    errorMessage: string;
    required?: boolean;
    className?: string;
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
    ({ type, name, value, label, onChange, onFocus, onBlur, hasError, errorMessage, required, className }, ref) => {
        const
            /**
             * Local state
             */
            [focusedInput, setFocusedInput] = useState<boolean>(false),

            /**
             * Handlers
             */
            handleFocus = () => {
                setFocusedInput(true);
                onFocus(name);
            },
            handleBlur = () => {
                setFocusedInput(false);
                onBlur(name);
            };

        return (
            <div className={`form__input-wrapper form__input-text ${hasError ? 'has-error' : 'has-no-error'} ${focusedInput ? "is-focused" : "is-not-focused"} ${value !== '' ? 'has-value' : 'has-no-value'} ${className}`}>
                <div className="input-wrapper__cover">
                    <label>{label}</label>

                    <input
                        type={type}
                        name={name}
                        ref={ref}
                        value={value}
                        onChange={onChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        required={required}
                    />
                </div>

                {hasError && errorMessage && errorMessage !== "" &&
                    <span className="error-message">{errorMessage}</span>}
            </div>
        );
    });

export default InputField;
