import React from "react";
import styled from "styled-components";
import { Ease } from "../../../Constants/EasingCurves";
import { Colours } from "../../../Constants/Colours";
import IconClose from "../../../Assets/SVGs/Icons/Close";

const Container = styled.div<{ display: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    display: ${(props) => (props.display ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    z-index: 9999;
`;

const CloseIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    height: calc(35rem / 16);
    width: calc(35rem / 16);
    cursor: pointer;
    border-radius: 50%;
    background: ${Colours.SecondaryHighlight};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.25s ${Ease.Smooth}, box-shadow 0.25s ${Ease.Smooth};

    svg {
        * {
            fill: white;
        }
    }

    &:hover,
    &:focus {
        background: ${Colours.IckonicPink};
        box-shadow: 0 0 10px ${Colours.IckonicPink};
    }
`;

const PopupContent = styled.div`
    position: relative;
    background: transparent; /* Let the gradient in FormContainer show */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    border: 2px solid #800080; /* Dark Purple border */
    max-width: 600px;
    width: 90%;
    text-align: center;
    color: white;
`;

interface EmailSubscriptionLeadPopupProps {
    display: boolean;
    setDisplay: (value: boolean) => void;
    children?: React.ReactNode;
}

const EmailSubscriptionLeadPopup: React.FC<EmailSubscriptionLeadPopupProps> = ({
    display,
    setDisplay,
    children,
}) => {
    return (
        <Container display={display}>
            <PopupContent>
                <CloseIcon onClick={() => setDisplay(false)}>{IconClose()}</CloseIcon>
                {children} {/* Wrap children with PopupContent */}
            </PopupContent>
        </Container>
    );
};

export default EmailSubscriptionLeadPopup;
