import React from "react";

const IconExit = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fillRule="evenodd"
            clipRule="evenodd"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } d="M16 2v7h-2V4H2v16h12v-5h2v7H0V2h16zm2 9V7l6 5-6 5v-4H8v-2h10z"> </path>
        </svg>
    )
};

export default IconExit;
