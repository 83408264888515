export const Colours = {
    IckonicPink: "#bb36b8",
    IckonicPinkSubtleHightlight: "#c53dc2",
    IckonicPinkHighlight: "#df4bdc",
    IckonicPinkDark: "#892686",
    IckonicPinkDarker: "#631960",
    IckonicPinkDesaturatedDark: '#2f252e',
    Text: "#E0E0E0",
    Accent: "#e8bb27",
    AccentDark: "#e5ae2c",
    TextBright: "#ffffff",
    TextDark: "#ac8bac",
    TextDarker: "#9B9B9B",
    SuccessText: "#1F9D01",
    Secondary : "#1D1D1D",
    SecondaryDarker : "#121212",
    SecondaryLighter : "#222",
    SecondaryHighlight : "#333",
    Tertiary : "#444444",
    TertiaryHighlight : "#777",
    LighterGrey : "#888",
    Transparent: "#ffffff00",
    Error: "#ff2e2e",
    ErrorDark: "#bd2525",
    HealthAndWellbeing: "#098577",
    Consciousness: "#EBB13E",
    GlobalCurrentEvents: "#4676E6",
    Documentaries: "#8240A5",
    HiddenKnowledge: "#901B1B"
};
