import React from "react";

const IconAlertBell = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } d="M15 21c0 1.598-1.392 3-2.971 3S9 22.598 9 21h6zm.137-17.055a2.103 2.103 0 01-1.041-1.82v-.003C14.097.95 13.158 0 12 0S9.903.95 9.903 2.122v.003a2.1 2.1 0 01-1.041 1.82C4.194 6.654 6.877 15.66 2 17.251V19h20v-1.749c-4.877-1.591-2.193-10.598-6.863-13.306zM12 1a1.001 1.001 0 010 2 1 1 0 010-2zM5.549 17c1.189-1.667 1.605-3.891 1.964-5.815.447-2.39.869-4.648 2.354-5.509 1.38-.801 2.956-.76 4.267 0 1.485.861 1.907 3.119 2.354 5.509.359 1.924.775 4.148 1.964 5.815H5.549z"> </path>
        </svg>
    )
};

export default IconAlertBell;
