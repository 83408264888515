import React from "react";

const IconLifeRing = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm8.975 16.383l-2.608-1.485a6.956 6.956 0 000-5.795l2.608-1.485C21.625 8.943 22 10.427 22 12s-.375 3.057-1.025 4.383zM2 12c0-1.573.375-3.057 1.025-4.383l2.608 1.485a6.956 6.956 0 000 5.795l-2.608 1.485A9.92 9.92 0 012 12zm5 0c0-2.757 2.243-5 5-5s5 2.243 5 5-2.243 5-5 5-5-2.243-5-5zm9.397-8.968L14.912 5.64C14.024 5.233 13.04 5 12 5s-2.024.233-2.912.64L7.603 3.032C8.932 2.378 10.421 2 12 2s3.068.378 4.397 1.032zM7.603 20.968l1.485-2.608c.888.407 1.872.64 2.912.64s2.024-.233 2.912-.64l1.485 2.608C15.068 21.622 13.579 22 12 22s-3.068-.378-4.397-1.032z"> </path>
        </svg>
    )
};

export default IconLifeRing;
