import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Cookies from 'js-cookie';

// Components
import InputWithLabel from "../Inputs/InputWithLabel";
import Card from "../Card";
import Heading from "../Text/Heading";
import PinkButton from "../Buttons/PinkButton";
import PrimaryText from "../Text/PrimaryText";
import NavLinkPinkTextButton from "../Buttons/NavLinkPinkTextButton";

// Helpers
import { ContainsAt } from "../../../Helpers/Utility";

// Api
import EmailFreeViewAPI from "../../../Api/EmailFreeView";

// Models
import { HeadingType } from "../../../Models/Enums/HeadingType";

// Constants
import { Colours } from "../../../Constants/Colours";
import { Fonts } from "../../../Constants/Fonts";
import { ResponsiveBreakpoints } from "../../../Constants/ResponsiveBreakpoints";
import { Ease } from "../../../Constants/EasingCurves";
import { RoutePaths } from "../../../Constants/RoutePaths";

// Assets
import IconCheckMark from "../../../Assets/SVGs/Icons/CheckMark";
import IconSpinLoader from "../../../Assets/SVGs/Icons/SpinLoader";


const Container = styled.div`
    h3 {
        margin: 0 0 8px 0;
        font-family: ${ Fonts.Primary };
        font-weight: 600;
        text-align: center;
        font-size: 12pt;
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
            font-size: 16pt;
        }
    }

    button {
        min-height: 38px;
        padding: 12px;
        position: relative;
        width: 100%;
        border: none;
        &.has-success {
             background: ${ Colours.IckonicPinkHighlight };
             box-shadow: 0 0 0 calc(2rem/16) ${ Colours.IckonicPinkHighlight };
             width: 38px !important;
             height: 38px;
             .button__icon {
                 border-radius: 50%;
                 animation-name: pulseHuge;
                 animation-duration: 700ms;
                 animation-timing-function: ${ Ease.LateSnap };
             }
        }

        .button__icon {
            width: 25px;
            height: 25px;
            margin: 0 auto;
            position: absolute;
            top: calc(50% - (13rem/16));
            left: calc(50% - (13rem/16));
            * {
                fill: black;
            }

            &.icon--loading {
                animation-name: spin;
                animation-duration: 1200ms;
                animation-iteration-count: infinite;
                animation-timing-function: ${ Ease.Smooth };
                * {
                    fill: white;
                }
            }
        }
    }

    input[type=email] {
        background: #2d2d2d;
        color: ${ Colours.TextBright };
        font-weight: 500;
        font-size: 14pt;
        padding: 10px;
        @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
            padding: 20px;
        }
    }


    // Card
    > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding: 1rem;
        background: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;

        // GDPR Disclaimer
        > p {
            font-size: 9pt;
            color: #ccc;
            text-align: center;
            margin: calc(8rem/16) 0 0 0;
            a {
                font-size: 9pt;
            }
        }

        @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }em/16)) {
            padding: 2rem 2rem 1rem 2rem;
        }

        @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }em/16)) {
            position: relative;
            border-radius: 7px;
            width: auto;
            height: auto;
            box-shadow: 0 0 0 2px rgba(197,61,194,0.5), 0 0 30px rgba(0,0,0,0.4);
            background: ${ Colours.SecondaryDarker } !important;

            // GDPR Disclaimer
            > p {
                max-width: calc(350rem/16);
            }
        }
    }
`;

const EmailFreeView: React.FC = (props: {
    handleClosePopUp?: () => void,
    isPopUpOpen?: boolean
}) => {
    const
        {
            handleClosePopUp,
            isPopUpOpen
        } = props,
        cookieName = 'emilFreeview',
        [ email, setEmail ] = useState(''),
        [ validEmail, setValidEmail ] = useState(false),
        [ showForm, setShowForm ] = useState(false),
        [ isLoading, setIsLoading ] = useState(false),
        [ success, setSuccess ] = useState(false),
        [ emailError, setEmailError ] = useState<string | undefined>(),
        controller = new AbortController(),

        // Updates email field value on user input
        handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
        },

        // Standard for proper email format
        validateEmail = (text: string, allowErrors: boolean) => {
            if (text.length <= 0 || text.trim().length <= 0) {
                allowErrors && setEmailError("E-mail cannot be empty");
                setValidEmail(false);
                return false;
            }

            if (!ContainsAt(text)) {
                allowErrors && setEmailError("Not a valid email address");
                setValidEmail(false);
                return false;
            }

            setEmailError(undefined);
            setValidEmail(true);
            return true;
        },

        // Sends user email address to the freeview api
        submitEmail = () => {

            // Checks is email is valid format before proceeding
            if (validateEmail(email, true)) {

                // enable loading status
                setIsLoading(true);
                EmailFreeViewAPI(controller, email)
                    .then((response) => {

                        // If we get a successful response from the api
                        if (response) {

                            // clear the email field
                            setEmail('');

                            // Set indefinite cookie
                            Cookies.set(cookieName, '');

                            // set success status
                            setSuccess(true);
                        }

                        // turn off loading regardless of api response
                        setIsLoading(false);
                    })
            }
        },

        handleBlur = () => {
            validateEmail(email, true);
        },

        handleSubmit = (event?: React.MouseEvent<HTMLButtonElement>) => {
            event?.preventDefault();
            submitEmail();
        },

        // Create a new function that calls handleSubmit with the event parameter
        handleClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
            handleSubmit(event);
        },

        handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                submitEmail();
            }
        };

    // Runs once, on component mount
    useEffect(() => {
        if (Cookies.get(cookieName) === undefined) {
            setShowForm(true);
        }
    }, []);

    useEffect(() => {
        if (Cookies.get(cookieName) !== undefined && handleClosePopUp !== undefined) {
            handleClosePopUp();
        }
    }, [ isPopUpOpen, handleClosePopUp ]);

    useEffect(() => {
        setTimeout(() => {
            if (success && handleClosePopUp) {
                handleClosePopUp();
            }
        }, 1500);
    }, [ success, handleClosePopUp ]);

    useEffect(() => {
        validateEmail(email, false);
    }, [ email ]);

    return (
        <Container>
            { showForm &&
                (
                    <Card>
                        <Heading type={ HeadingType.H3 }>
                            Enter your email to watch for free!
                        </Heading>

                        <InputWithLabel
                            placeholder="you@example.com"
                            type="email"
                            isValid={ !emailError }
                            value={ email }
                            onChange={ handleChange }
                            onKeyPress={ handleKeyPress }
                            onBlur={ handleBlur }
                            label=""
                            errorMessage={ emailError }
                        />

                        <PinkButton
                            className={ `form__submit ${ success ? 'has-success' : 'is-waiting' }` }
                            onClick={ (event) => handleClick(event) }
                            disabled={ !validEmail }
                        >
                            { success ?
                                <div className="button__icon icon--success">{ IconCheckMark() }</div>
                                :
                                isLoading ?
                                    <div className="button__icon icon--loading">{ IconSpinLoader() }</div>
                                    :
                                    'Submit'
                            }
                        </PinkButton>

                        <PrimaryText>
                            By clicking submit you're agreeing to allow us to contact you with offers and new releases. Please see our <NavLinkPinkTextButton to={ RoutePaths.TermsOfUse }>terms and conditions</NavLinkPinkTextButton> for more informations.
                        </PrimaryText>
                    </Card>
                )
            }
        </Container>
    );
};

export default EmailFreeView;
