import { NavLink } from "react-router-dom";
import { GetContentRedirect } from "../../Helpers/Utility";
import styled from "styled-components";
import ICarouselContent from "../../Models/DTOs/ICarouselContentDTO";
import ImageLazyLoad from "../UI/ImageLazyLoad";
import Heading from "../UI/Text/Heading";
import { HeadingType } from "../../Models/Enums/HeadingType";
import {Ease} from "../../Constants/EasingCurves";
import {ResponsiveBreakpoints} from "../../Constants/ResponsiveBreakpoints";

const
    OverlayContainer = styled.div`
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 9%;
        text-align: center;
        vertical-align: middle;
        display: flex;
        align-items: flex-end;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);

    `,
    ReleaseDayOfWeekLabel = styled.div`
        p {
            padding: 5px;
            background: rgba(0,0,0,0.85);
            color: white;
            position: absolute;
            top: 16px;
            left: 0;
            text-transform: uppercase;
            text-align: center;
            line-height: 1.2;
            width: calc(100rem/16);
            margin: 0;
            font-size: calc(10rem/16);
            box-shadow: rgb(223, 75, 220) 0px 0px calc(0.75rem) 0px;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            span {
                display: block;
                font-size: calc(14rem/16);
            }
        }
    `;

const Container = styled(NavLink)<{overlay: string, height: number}>`
    cursor: pointer;
    text-decoration: none;
    position: relative;
    border-radius: calc(10rem/16);
    overflow: hidden;
    width: 100%;
    transition: transform .25s ${ Ease.Smooth };
    img {
        width: 100%;
        height: auto;
    }

    &:hover,
    &:focus {
        transform: scale(1.015);
    }

    h3 {
        margin: 0 !important;
        text-transform: uppercase;
        font-size: 10pt;
        text-align: left;
        transition: transform .25s ${ Ease.Smooth };
    }

    :has(${OverlayContainer}:hover),
    :has(${OverlayContainer}:focus) {
        transform: scale(1);
        h3 {
            transform: translateY(calc(-10rem/16));
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallMobileBreakpoint }rem/16)) {
        width: calc(50% - (8rem/16));
        h3 {
            //font-size: 14pt;
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.MediumMobileBreakpoint }rem/16)) {
        h3 {
            font-size: 12pt;
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.LargeMobileBreakpoint }rem/16)) {

        h3 {
            font-size: 12pt;
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.XLMobileBreakpoint }rem/16)) {
        h3 {
            font-size: 14pt;
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.SmallTabletBreakpoint }rem/16)) {
        width: calc(33.333333% - (10.666666rem/16));
        h3 {
            font-size: 12pt;
        }
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.TabletBreakpoint }rem/16)) {
        width: calc(25% - (12rem/16));
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.DesktopBreakpoint }rem/16)) {
        width: calc(20% - (12.8rem/16));
    }

    @media screen and (min-width: calc(${ ResponsiveBreakpoints.WideScreenBreakpoint }rem/16)) {
        width: calc(16.666666% - (13.333333rem/16));
    }
`;

function CarouselItemPortrait(props: {Content: ICarouselContent, showOverlay?: boolean, height?: number, releaseDayOfWeek?: string}) {
    let redirect = GetContentRedirect(props.Content.ContentType, props.Content.Id);

    return (
        <Container
            height={ props.height ? props.height : 270 }
            overlay={ props.showOverlay ? props.showOverlay.toString() : "false"}
            to={ redirect != null ? redirect : "/" }
        >
            { props.releaseDayOfWeek !== "" && props.releaseDayOfWeek !== undefined && (
                <ReleaseDayOfWeekLabel>
                    <p>
                        {
                            props.releaseDayOfWeek === 'Wednesday' ||
                            props.releaseDayOfWeek === 'Thursday' ||
                            props.releaseDayOfWeek === 'Friday' ||
                            props.releaseDayOfWeek === 'Sunday' ? "New Episode" : "Returns"
                        } <span>{ props.releaseDayOfWeek }</span>
                    </p>
                </ReleaseDayOfWeekLabel>
            ) }

            <ImageLazyLoad source={ props.Content.PortraitThumbnail } />

            {
                props.showOverlay ?
                    (
                        <OverlayContainer className="carousel-item-portrait__overlay">
                            <Heading type={ HeadingType.H3 }>
                                { props.Content.Title }
                            </Heading>
                        </OverlayContainer>
                    ) : null
            }
        </Container>
    );
}

export default CarouselItemPortrait;
