import styled from "styled-components";
import { AxiosError } from "axios";

// Api
import { AddRating, DeleteRating } from "../../../Api/Rating";

// Models
import { IRatingDTO } from "../../../Models/DTOs/IRatingDTO";
import { RatingButtonType } from "../../../Models/Enums/RatingButtonType";

// Assetes
import IconThumbsUp from "../../../Assets/SVGs/Icons/ThumbsUp";
import IconThumbsDown from "../../../Assets/SVGs/Icons/ThumbsDown";

const Button = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    width: auto;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const
    buttonStyles = `
        background: transparent;
        border: 0;
        margin: 0 4px 0 0;
        width: 20px;
        height: 20px;
        svg {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
                `,
    ThumbUp = styled.div`${ buttonStyles }`,
    ThumbDown = styled.div`${ buttonStyles }`;

function RatingBtn(data: {
    UserLiked: boolean;
    UserDisliked: boolean;
    Type: RatingButtonType;
    activeColour: string;
    IconSize: number;
}) {
    if (data.Type === RatingButtonType.Like) {
        return (
            <ThumbUp>
                <IconThumbsUp iconFill={ data.UserLiked && data.Type === RatingButtonType.Like ? data.activeColour : "#626262" } />
            </ThumbUp>
        );
    }

    return (
        <ThumbDown>
            <IconThumbsDown iconFill={ data.UserDisliked && data.Type === RatingButtonType.Dislike ? data.activeColour : "#626262" } />
        </ThumbDown>
    );
}

function LikeDislikeButton(props: {
    videoId: number;
    aspNetUserId: string;
    IconSize: number;
    Rating: IRatingDTO;
    isDisabled: boolean;
    Type: RatingButtonType;
    SetRatigActive: (value: boolean) => void;
    testId?: string,
    children?: React.ReactNode
}) {
    const controller = new AbortController();
    const activeColour =
        props.Type === RatingButtonType.Like ? "#57fb72" : "#ff4747";

    async function PostRating() {

        //If user already likes or dislikes rating will be removed
        if (
            (props.Type === RatingButtonType.Like && props.Rating.UserLiked) ||
            (props.Type === RatingButtonType.Dislike &&
                props.Rating.UserDisliked)
        ) {
            await DeleteContentRating();
            return;
        }

        await AddContentRating(
            props.Type === RatingButtonType.Like ? true : false
        );
    }

    async function AddContentRating(isLike: boolean) {
        props.SetRatigActive(true);
        let result = await AddRating(props.videoId, props.aspNetUserId, isLike, controller);

        if (!result || result instanceof AxiosError) {
            return;
        }

        switch(props.Type){
            case RatingButtonType.Like:
                props.Rating.LikeCount++;

                if(props.Rating.UserDisliked){
                    props.Rating.DislikeCount--
                }
                break;
            case RatingButtonType.Dislike:
                props.Rating.DislikeCount++

                if(props.Rating.UserLiked){
                    props.Rating.LikeCount--
                }
                break;
        }

        props.Rating.UserLiked = props.Type === RatingButtonType.Like ? true : false;
        props.Rating.UserDisliked = props.Type === RatingButtonType.Dislike ? true : false;

        props.SetRatigActive(false);
    }

    async function DeleteContentRating() {
        props.SetRatigActive(true);
        let result = await DeleteRating(
            props.videoId,
            props.aspNetUserId,
            controller
        );

        if (!result || result instanceof AxiosError) {
            return;
        }

        switch(props.Type){
            case RatingButtonType.Like:
                props.Rating.LikeCount--;
                break;
            case RatingButtonType.Dislike:
                props.Rating.DislikeCount--;
                break;
        }

        props.Rating.UserLiked = false;
        props.Rating.UserDisliked = false;

        props.SetRatigActive(false);
    }

    return (
        <Button onClick={PostRating} disabled={props.isDisabled} data-testid={props.testId}>
            <RatingBtn
                IconSize={props.IconSize}
                Type={props.Type}
                UserDisliked={props.Rating.UserDisliked}
                UserLiked={props.Rating.UserLiked}
                activeColour={activeColour}
            />

            { props.children }
        </Button>
    );
}

export default LikeDislikeButton;
