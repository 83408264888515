import React from "react";
import ICarouselContentDTO from "../../../Models/DTOs/ICarouselContentDTO";
import {NavLink} from "react-router-dom";
import {RoutePaths} from "../../../Constants/RoutePaths";
import IconArrowCarrot from "../../../Assets/SVGs/Icons/ArrowCarrot";
import DavidBig from '../../../Assets/Images/FrontPage/david-big.png';

type TwoColumnWithLatestArticlesProps = {
    articles?: ICarouselContentDTO[] | undefined;
    heading: React.ReactNode;
    body: React.ReactNode;
};

const TwoColumnWithLatestArticles: React.FC<TwoColumnWithLatestArticlesProps> = (
    {
        articles,
        heading,
        body,
    }
) => {
    const isLoading = !articles;
    return (
        <section className="layout layout--two-column-with-latest-articles u-relative_hidden">
            <div className="two-column-with-latest-articles__content u-relative_hidden">
                {heading && heading}

                {body && body}
            </div>

            <img src={DavidBig} alt="David Icke"/>

            <div className="two-column-with-latest-articles__inner">
                {articles && articles.map((item, i) => (
                    <div className="two-column-with-latest-articles__article">
                        <NavLink to={item.Id ? RoutePaths.ReadArticle(item.Id) : "#"}
                                 className="two-column-with-latest-articles__article-image is-sixteen-nine-aspect-ratio"
                        >
                            {isLoading ?
                                (
                                    <div className="loader--side-to-side"/>
                                )
                                :
                                (
                                    <div
                                        className="u-full_cover_absolute"
                                        style={{
                                            background: `url("${item.Thumbnail ?? ""}") center/cover no-repeat`,
                                        }}
                                    />
                                )
                            }

                            <div className="two-column-with-latest-articles__article-image-gradient"/>
                        </NavLink>

                        <div className="two-column-with-latest-articles__article-content">
                            <h3>
                                <NavLink to={item.Id ? RoutePaths.ReadArticle(item.Id) : "#"}>{item.Text}</NavLink>
                            </h3>

                            <NavLink
                                to={item.Id ? RoutePaths.ReadArticle(item.Id) : "#"}>{IconArrowCarrot({iconFill: "white"})}</NavLink>
                        </div>

                        <p>
                            <NavLink to={item.Id ? RoutePaths.ReadArticle(item.Id) : "#"}>{item.Title}</NavLink>
                        </p>
                    </div>
                ))}
            </div>

            <div
                className="category-featured-items__gradient-overlay-top u-full_cover_absolute"
                style={{background: `linear-gradient(0deg, rgba(0,0,0,0) 0%, #010107 100%)`}}
            />

            <div
                className="category-featured-items__gradient-overlay-bottom u-full_cover_absolute"
                style={{background: `linear-gradient(0deg, #010107 0%, rgba(0,0,0,0) 100%)`}}
            />
        </section>
    );
};

export default TwoColumnWithLatestArticles;
