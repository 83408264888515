import { useState, useEffect } from "react";

// Api
import { GetVideo, GetLatestVideo } from "../Api/Video";
import { GetSeries } from "../Api/Series";

// Models
import ICarouselContentDTO, {
    transformVideoToCarouselContent,
    transformSeriesToCarouselContent,
} from "../Models/DTOs/ICarouselContentDTO";
import IVideoDTO from "../Models/DTOs/IVideoDTO";
import ISeriesDTO from "../Models/DTOs/ISeriesDTO";

type CuratedItem = {
    type: string;
    id: number;
};

interface IUseFetchSpecificContentItems {
    items: ICarouselContentDTO[];
    loading: boolean;
    error: string;
}

/**
 * A hook that fetches and transforms a list of curated items. Each item
 * can be a Film or a Series, so we have to call different endpoints accordingly.
 */
export default function useFetchSpecificContentItems(
    curatedItems: CuratedItem[],
    country: string,
    aspNetUserId: string
): IUseFetchSpecificContentItems {
    const [items, setItems] = useState<ICarouselContentDTO[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (curatedItems.length === 0) return;

        let isCancelled = false;
        const abortController = new AbortController();

        async function fetchData() {
            setLoading(true);

            try {
                // We'll map over each curatedItem and fetch data accordingly
                const promises = curatedItems.map(async (cur) => {
                    if (cur.type === "Film") {
                        const video: IVideoDTO = await GetVideo(
                            cur.id,
                            country,
                            abortController
                        );
                        return transformVideoToCarouselContent(video);
                    } else {
                        const series: ISeriesDTO = await GetSeries(
                            cur.id,
                            abortController
                        );
                        return transformSeriesToCarouselContent(series);
                    }
                });

                // Wait for all fetches to resolve
                const results = await Promise.all(promises);

                if (!isCancelled) {
                    setItems(results);
                }
            } catch (err) {
                if (!isCancelled) {
                    console.error("Error fetching curated content items:", err);
                    setError("Failed to fetch curated content items.");
                }
            } finally {
                if (!isCancelled) {
                    setLoading(false);
                }
            }
        }

        fetchData();

        return () => {
            isCancelled = true;
            abortController.abort();
        };
    }, [curatedItems]);

    return { items, loading, error };
}
