import React, { useState, useEffect, useRef } from "react";

interface InputDropDownProps {
    options: string[];
    selectedValue: string;
    onChange: (value: string) => void;
    onClick?: () => void,
    placeholder?: string,
    absolutePosition: boolean,
}

const InputDropDown: React.FC<InputDropDownProps> = ({ options, selectedValue, onChange, onClick = () => {}, placeholder = "Select an option", absolutePosition }) => {
    const
        [isOpen, setIsOpen] = useState(false),
        dropdownRef = useRef<HTMLDivElement>(null),

        handleToggleDropdown = () => {
            setIsOpen((prev) => !prev);
            onClick();
        },

        handleOptionClick = (value: string) => {
            onChange(value);
            onClick();
            setIsOpen(false);
        },

        handleClose = () => {
            if (isOpen) {
                onClick();
            }

            setIsOpen(false);
        },

        handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

    useEffect(() => {
        const handleClickOutsideWithState = (e: MouseEvent) => {
            handleClickOutside(e);
            if (isOpen) {
                onClick();
            }
        };

        document.addEventListener("mousedown", handleClickOutsideWithState);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideWithState);
        };
    }, [isOpen]);

    return (
        <div className="ickonic-dropdown" ref={dropdownRef}>
            <div className={`ickonic-dropdown__selected ${isOpen ? "is-open" : ""}`} onClick={handleToggleDropdown}>
                {selectedValue || placeholder}
                <span className={`ickonic-dropdown__arrow ${isOpen ? "is-open" : ""}`}>▼</span>
            </div>

            {isOpen && (
                <div className={`ickonic-dropdown__options ${absolutePosition ? 'is-absolute-positioned' : 'is-static-positioned'}`}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`ickonic-dropdown__option ${option === selectedValue ? "is-selected" : ""}`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default InputDropDown;
