const IconRefused = (props?: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            width="32"
            height="32"
            className={props?.className}
        >
            <circle cx="18" cy="18" r="16" fill="#DD2E44" /> {/* Red */}
            <path
                fill="#FFF" // White cross
                d="M12 12l12 12m-12 0L24 12"
                stroke="#FFF"
                strokeWidth="2"
            />
        </svg>
    );
};

export default IconRefused;
