import React, {useEffect} from "react";

// Components
import ButtonPillUppercase from "../Buttons/ButtonPillUppercase";
import {GetNotificationBar, GetNotificationBarAlt} from "../../Api/NotificationBar";

type NotificationBarProps = {
    isDarkMode: boolean;
};

const NotificationBar: React.FC<NotificationBarProps> = ({ isDarkMode }) => {

    useEffect(() => {
        const abortController = new AbortController;
        // GetNotificationBar(abortController);
        // GetNotificationBarAlt();
    }, []);

    return (
        // <div
        //     className={`global__notification-bar ${isDarkMode ? 'is-dark-mode' : 'has-light-mode'}`}
        // >
        //     <p>
        //         Trending: CEO Shooter Deep-Dive
        //     </p>
        //
        //     <ButtonPillUppercase label="Watch Now" className="" link="Watch/1245" />
        // </div>
        <>
        </>
    );
};

export default NotificationBar;
