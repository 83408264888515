import React, { useState } from "react";
import LazyLoad from "react-lazy-load";

// Assets
import David from '../../../Assets/Images/FrontPage/david-portrait.png';
import Gareth from '../../../Assets/Images/FrontPage/gareth-portrait.png';
import Jaymie from '../../../Assets/Images/FrontPage/jaymie-portrait.png';
import Chrissy from '../../../Assets/Images/FrontPage/chrissy-portrait.png';
import Richard from '../../../Assets/Images/FrontPage/richard-portrait.png';
import Kristen from '../../../Assets/Images/FrontPage/kristen.png';

type PresenterShowcaseProps = {
    isDarkMode: boolean;
};

const PresenterShowcase: React.FC<PresenterShowcaseProps> = ({ isDarkMode }) => {
    // Track which presenter is hovered (or -1 if none)
    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);

    // Example presenters array (you could fetch or define these in props)
    const presenters = [
        { name: "Jaymie Icke", role: "Speaker Role", image: Jaymie },
        { name: "Gareth Icke", role: "Speaker Role", image: Gareth },
        { name: "David Icke", role: "Speaker Role", image: David },
        { name: "Christianne van Wijk", role: "Speaker Role", image: Chrissy },
        { name: "Richard Willett", role: "Speaker Role", image: Richard },
        { name: "Kristen Bitting", role: "Speaker Role", image: Kristen }
    ];

    return (
        <div
            className={`layout--presenter-showcase ${
                isDarkMode ? "is-dark-mode" : "has-light-mode"
            }`}
        >
            <h1>
                Meet <span>The Voices of Change</span>
            </h1>

            <div className="presenter-showcase__inner">
                <div className="presenter-showcase__track">
                    {presenters.map((presenter, index) => (
                        <div
                            key={index}
                            className={`presenter-showcase__presenter u-relative_hidden
                        ${hoveredIndex === index ? "is-hovered" : "is-not-hovered"}
                        ${hoveredIndex !== -1 && hoveredIndex !== index ? "sibling-has-hover" : ""}`}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(-1)}
                        >
                            <div className="presenter-showcase__presenter-inner u-full_cover_absolute">
                                <div
                                    className="presenter-showcase__speaker-img u-full_cover_absolute"
                                    style={{
                                        backgroundImage: `url(${presenter.image})`,
                                        backgroundPosition: 'center bottom',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat'
                                    }}
                                />

                                <div className="presenter-showcase__content">
                                    <h3>{presenter.name}</h3>
                                    {/*<p>{presenter.role}</p>*/}
                                </div>

                                <div className="presenter-showcase__gradient-overlay u-full_cover_absolute"/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PresenterShowcase;
