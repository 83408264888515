import React from "react";

const IconChrome = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={props?.className}
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="32"
            fill="none"
            viewBox="0 0 33 32"
        >
            <path
                fill="#fff"
                d="M16.834 24.902a8.903 8.903 0 1 0 0-17.806 8.903 8.903 0 0 0 0 17.806"
            ></path>
            <path
                fill="#229342"
                d="M5.32 11.625a46 46 0 0 0-2.342-3.623A15.997 15.997 0 0 0 16.835 32q2.208-3.098 3-4.467 1.516-2.63 3.927-7.532V20a7.998 7.998 0 0 1-13.857.002Q6.632 13.9 5.32 11.625"
            ></path>
            <path
                fill="#FBC116"
                d="M16.834 32a16 16 0 0 0 16-16 16 16 0 0 0-2.146-8q-4.546-.448-6.711-.448-2.454 0-7.144.448L16.831 8a7.999 7.999 0 0 1 6.93 12z"
            ></path>
            <path
                fill="#1A73E8"
                d="M16.834 22.335a6.334 6.334 0 1 0 0-12.668 6.334 6.334 0 0 0 0 12.667"
            ></path>
            <path
                fill="#E33B2E"
                d="M16.834 8h13.854a15.998 15.998 0 0 0-27.71.002l6.927 12h.002A8 8 0 0 1 16.834 8"
            ></path>
        </svg>
    )
};

export default IconChrome;
