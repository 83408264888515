import React from "react";
import {NavLink} from "react-router-dom";
import styled from "styled-components";

interface ButtonPillUppercaseProps {
    label: string,
    className: string,
    link: string,
    icon?: React.ReactNode,
    openInNewWindow?: boolean,
    callbackFunction?: () => void,
    onClick?: () => void,
}

const ButtonPillUppercase: React.FC<ButtonPillUppercaseProps> = ({
    label = 'Click Here',
    className = '',
    link = '',
    icon,
    callbackFunction = () => {} // Provide a default no-op function if undefined
}) => {
    return link === "" ? (
        <button className={`ickonic-button ickonic-button--pill-uppercase button ${className}`}
                onClick={callbackFunction}>
            <div className="ickonic-button__inner">
                {label}
                {icon && icon}
            </div>
        </button>
) : (
        <NavLink to={link} className={`ickonic-button ickonic-button--pill-uppercase button ${className}`}
                 onClick={callbackFunction}>
            <div className="ickonic-button__inner">
                {label}
                {icon && icon}
            </div>
        </NavLink>
);
};

export default ButtonPillUppercase;
