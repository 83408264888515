import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { GetFeaturedContent, GetFilms } from "../../../../Api/Carousel";
import CarouselItem from "../../../../Components/Carousels/CarouselItem";
import FeaturedCarousel from "../../../../Components/Carousels/FeaturedCarousel";
import ListContainer from "../../../../Components/UI/List/ListContainer";
import FeaturedSliderLoader from "../../../../Components/UI/PageLoaders/FeaturedSliderLoader";
import { AuthValidSubOnly } from "../../../../Helpers/RouteAuth";
import { SetTitle } from "../../../../Helpers/Utility";
import ICarouselContentDTO from "../../../../Models/DTOs/ICarouselContentDTO";
import { ContentType } from "../../../../Models/Enums/ContentType";
import { ResponsiveBreakpoints } from "../../../../Constants/ResponsiveBreakpoints";
import VideoSingleLoader from "../../../../Components/UI/PageLoaders/VideoSingleLoader";
import { RoutePaths } from "../../../../Constants/RoutePaths";
import { useNavigate } from "react-router-dom";

// Context
import { UserAuthenticationContext } from "../../../../Context/UserAuthenticationContext";
import {NeedsRenew} from "../../../../Helpers/UserUtility";

const Container = styled.section`
    padding: 1rem;
    width: 100%;
    max-width: calc(1616rem/16);
    margin: 0 auto;
    box-shadow: calc(1rem/16) 0 0 0 rgba(0,0,0,0.5), calc(-1rem/16) 0 0 0 rgba(0,0,0,0.5);
    @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}em/16)) {
        padding: 1.5rem;
    }
`;

const take = 30;

function FilmsScreen() {
    const authCtx = useContext(UserAuthenticationContext);
    const navigate = useNavigate();

    const [featuredData, setFeaturedData] = useState<ICarouselContentDTO[] | null>(null);
    const [initialFilms, setInitialFilms] = useState<ICarouselContentDTO[] | null>(null);
    const [films, setFilms] = useState<ICarouselContentDTO[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

    useEffect(() => {
        // Wait for user details
        if (!authCtx.userData) {
            return;
        }

        const needsRenew = NeedsRenew(authCtx.userData);
        if (authCtx.doesAuthTokenExist() && needsRenew) {
            // If unauthorized or no valid sub, navigate or handle accordingly
            navigate(RoutePaths.Renew);
            return;
        }

        async function fetchInitialData() {
            setLoadingInitial(true);

            const controller = new AbortController();
            const userId = authCtx.userData.AspNetUserId;
            const countryCode = authCtx.userData.CurrentCountryCode;

            // Fetch featured content
            const featuredResult = await GetFeaturedContent(countryCode, userId, controller);
            let featuredRes: ICarouselContentDTO[] = [];
            if (featuredResult && !(featuredResult instanceof AxiosError)) {
                featuredRes = featuredResult;
            }

            // Fetch initial films
            const filmsResult = await GetFilms(countryCode, userId, take, 0);
            let filmsRes: ICarouselContentDTO[] = [];
            if (filmsResult && !(filmsResult instanceof AxiosError)) {
                filmsRes = filmsResult;
            }

            SetTitle("Films");

            setFeaturedData(featuredRes);
            setInitialFilms(filmsRes);
            setLoadingInitial(false);
        }

        fetchInitialData();
    }, [authCtx.userData, navigate]);

    async function loadMoreFilms(skip: number) {
        if (!authCtx.userData) {
            return;
        }

        const result = await GetFilms(
            authCtx.userData.CurrentCountryCode,
            authCtx.userData.AspNetUserId,
            take,
            skip
        );

        if (!result || result instanceof AxiosError || result.length <= 0) {
            setHasMore(false);
            return;
        }

        setFilms((prev) => [...prev, ...result]);
    }

    if (loadingInitial) {
        return (
            <>
                <FeaturedSliderLoader />
                <Container>
                    <ListContainer>
                        {[...Array(take - 5)].map((_, i) => (
                            <VideoSingleLoader paddingTop="8" key={i} />
                        ))}
                    </ListContainer>
                </Container>
            </>
        );
    }

    // Merged Lists of films
    const mergedLists = initialFilms ? [...initialFilms, ...films] : [];

    return (
        <>
            {featuredData && (
                <FeaturedCarousel
                    content={featuredData}
                    allowedContent={[ContentType.Film]}
                />
            )}

            <Container>
                <InfiniteScroll
                    dataLength={mergedLists.length}
                    hasMore={hasMore}
                    next={() => loadMoreFilms(mergedLists.length)}
                    loader={
                        <ListContainer>
                            {[...Array(5)].map((_, i) => (
                                <VideoSingleLoader paddingTop="8" key={i} />
                            ))}
                        </ListContainer>
                    }
                >
                    <ListContainer>
                        {mergedLists.map((content, index) => (
                            <CarouselItem
                                key={index.toString() + content.Id}
                                Content={content}
                                disableOverlay={true}
                            />
                        ))}
                    </ListContainer>
                </InfiniteScroll>
            </Container>
        </>
    );
}

export default FilmsScreen;
