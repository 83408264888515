import React from "react";

export const IconGear = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode }) => {
    return (
        <svg
            className={ props?.className }
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } d="M24 13.616v-3.232l-2.869-1.02a9.445 9.445 0 00-.811-1.955l1.308-2.751-2.285-2.285-2.751 1.307a9.468 9.468 0 00-1.955-.811L13.616 0h-3.232L9.363 2.869a9.413 9.413 0 00-1.955.811L4.657 2.372 2.372 4.657 3.68 7.409a9.39 9.39 0 00-.811 1.955L0 10.384v3.232l2.869 1.02a9.39 9.39 0 00.811 1.955l-1.308 2.751 2.285 2.286 2.751-1.308a9.468 9.468 0 001.955.811L10.384 24h3.232l1.021-2.869a9.445 9.445 0 001.955-.811l2.751 1.308 2.285-2.286-1.308-2.751a9.445 9.445 0 00.811-1.955L24 13.616zM12 16a4 4 0 110-8 4 4 0 010 8z"> </path>
        </svg>
    )
};


export default IconGear;
