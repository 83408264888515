import React from "react";

export const IconCheckMark = (props?: { iconFill?: string, className?: string, defs?: React.ReactNode, stroke?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            { props?.defs ? props.defs : null }
            <path fill={ props?.iconFill } stroke={ props?.stroke } d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"> </path>
        </svg>
    )
};

export default IconCheckMark;
