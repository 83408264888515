import styled from "styled-components";
import { useState } from "react";

// Components
import SearchFilters from "../UI/Navigation/SearchFilters";
import SearchScreen from "../../Screens/SearchScreen";

// Models
import { ISearchFilterDTO } from "../../Models/DTOs/ISearchFilterDTO";

// Constants
import { HeaderProperties } from "../../Constants/HeaderProperties";
import { Ease } from "../../Constants/EasingCurves";

// Assets
import IconSearchMagnifier from "../../Assets/SVGs/Icons/SearchMagnifier";
import {GetDefaultSearchFilters} from "../../Helpers/Utility";

const styledIcon = `
    cursor: pointer;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${ HeaderProperties.height };
`;

const SearchIcon = styled.div`
    ${ styledIcon }
    svg {
        padding: 3px;
        * {
            fill: #bcbcbc;
            transition: fill .15s ${ Ease.Smooth };
        }
    }

    &:hover,
    &:active {
        svg {
            * {
                fill: white;
            }
        }
    }
`;

function SearchBar(props: {
    currentCountryCode: string;
}) {
    const [isActive, setIsActive] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [searchFilter, setSearchFilter] = useState<ISearchFilterDTO>(GetDefaultSearchFilters());

    function OnActiveToggle(value: boolean) {
        setIsLoaded(true);
        setIsActive(value);

        // Close the mobile menu if open
        //props.toggleMobileMenuClosed();
    }

    function SetIsActive(value: boolean) {
        setIsActive(value);
    }

    function onSearchUpdate(value: string) {
        setSearchText(value);
    }

    function onSearchFilterUpdate(updatedFilters: ISearchFilterDTO) {
        const newFilters: ISearchFilterDTO = { ...updatedFilters };
        setSearchFilter(newFilters);
    }

    function clearSearch() {
        setSearchText("");
    }

    return (
        <div style={{ margin: '0 0 0 auto', position: 'relative', zIndex: 6 }}>
            <SearchFilters
                Header={
                    <>
                        <SearchIcon
                            onClick={() => OnActiveToggle(!isActive)}
                        >
                            <IconSearchMagnifier />
                        </SearchIcon>
                    </>
                }
                isLoaded={isLoaded}
                isActive={isActive}
                setIsActive={SetIsActive}
                mobileLayout={false}
                searchFilter={searchFilter}
                searchText={searchText}
                onSearchUpdate={ onSearchUpdate }
                onSearchFilterUpdate={onSearchFilterUpdate}
            >
                <SearchScreen
                    searchText={searchText}
                    searchFilters={searchFilter}
                    currentCountryCode={props.currentCountryCode}
                    disablePopUp={ () => SetIsActive(false) }
                />
            </SearchFilters>
        </div>
    );
}

export default SearchBar;
