// CarouselDefinitions.ts
import { RoutePaths } from "../../Constants/RoutePaths";
import { Colours } from "../../Constants/Colours";

export type CarouselKey =
    | "WeeklyShows"
    | "JustReleased"
    | "Trending"
    // | "CustomCarousels"
    | "TopRated"
    | "OriginalFilms"
    | "OriginalSeries"
    | "HealthAndWellbeing"
    | "Documentaries"
    | "OccultAndHiddenKnowledge"
    | "DavidIcke"
    | "Consciousness";

export interface ICarouselDefinition {
    componentType: "portrait" | "landscape";
    heading: string;
    accentColor?: string;
    buttonText?: string;
    buttonLink?: string;
}

export const CarouselDefinitions: Record<CarouselKey, ICarouselDefinition> = {
    WeeklyShows: {
        componentType: "portrait",
        heading: "Weekly Shows",
        accentColor: undefined,
        buttonText: "View All",
        buttonLink: RoutePaths.WeeklyShows,
    },
    JustReleased: {
        componentType: "landscape",
        heading: "Just Released",
    },
    Trending: {
        componentType: "landscape",
        heading: "Trending",
    },
    // CustomCarousels: {
    //     componentType: "landscape",
    //     heading: "Favorites",
    // },
    DavidIcke: {
        componentType: "landscape",
        heading: "David Icke",
        accentColor: Colours.GlobalCurrentEvents,
        buttonText: "View All",
        buttonLink: RoutePaths.DavidIcke,
    },
    OriginalFilms: {
        componentType: "portrait",
        heading: "Films",
        buttonText: "View All",
        buttonLink: RoutePaths.Films,
    },
    TopRated: {
        componentType: "landscape",
        heading: "Top Rated",
    },
    OriginalSeries: {
        componentType: "landscape",
        heading: "Original Series",
        accentColor: Colours.IckonicPink,
        buttonText: "View All",
        buttonLink: RoutePaths.Series,
    },
    Consciousness: {
        componentType: "landscape",
        heading: "Consciousness",
        accentColor: Colours.Consciousness,
        buttonText: "View All",
        buttonLink: RoutePaths.Category(5),
    },
    HealthAndWellbeing: {
        componentType: "landscape",
        heading: "Health & Wellbeing",
        accentColor: Colours.HealthAndWellbeing,
        buttonText: "View All",
        buttonLink: RoutePaths.Category(21),
    },
    Documentaries: {
        componentType: "landscape",
        heading: "Documentaries",
        accentColor: Colours.Documentaries,
        buttonText: "View All",
        buttonLink: RoutePaths.Category(3),
    },
    OccultAndHiddenKnowledge: {
        componentType: "landscape",
        heading: "Occult & Hidden Knowledge",
        accentColor: Colours.HiddenKnowledge,
        buttonText: "View All",
        buttonLink: RoutePaths.Category(29),
    },
};
