import axios, { AxiosError } from "axios";
import config from "../Constants/Config";

const BACKEND_URL = process.env.REACT_APP_API_URL + config.email;

export default async function EmailFreeViewAPI(abortController: AbortController, email: string) {
    const fetchUrl = BACKEND_URL + "/FreeView/?email=" + email;
    const response = await axios
        .post(fetchUrl, {
            signal: abortController.signal,
        })
        .then((response) => {
            return response.data as boolean;
        })
        .catch((error: AxiosError) => {
            console.log("Email error");
            return undefined;
        });

    return response;
};
