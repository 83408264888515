import React, {useEffect, useRef, useState} from 'react';

interface AccordionProps {
    heading: string;
    body: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ heading, body }) => {
    const
        [isActive, setIsActive] = useState(false),
        [height, setHeight] = useState(0),

        bodyRef = useRef<HTMLDivElement>(null),

        calculateBodyHeight = () => {
            if (isActive && bodyRef.current) {
                const newHeight = bodyRef.current.clientHeight;
                setHeight(newHeight);
            }
        },

        handleOnClick = () => {
            let newHeight = 0;

            if (!isActive && bodyRef.current) {
                newHeight = bodyRef.current.clientHeight;
            }

            // Use the state setters from useState
            setIsActive(!isActive);
            setHeight(newHeight);
        };

    useEffect(() => {
        // Add the resize event listener
        window.addEventListener('resize', calculateBodyHeight);

        // Cleanup: remove the event listener when the component unmounts or when dependencies change
        return () => {
            window.removeEventListener('resize', calculateBodyHeight);
        };
    }, [isActive]);

    return (
        <div className={`layout--accordion ${isActive ? 'is-active' : 'is-not-active'} u-relative_hidden`}>
            <div className="accordion__heading">
                {heading !== '' && (
                    <h4 onClick={handleOnClick}>
                        {heading}
                    </h4>
                )}

                <svg
                    onClick={handleOnClick}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fillRule="evenodd"
                    clipRule="evenodd"
                >
                    <path d="M11 11V0h1v11h11v1H12v11h-1V12H0v-1h11z" />
                </svg>
            </div>

            <div
                className="accordion__content"
                style={{
                    // Use 'auto' if height is 0, otherwise use the numeric height
                    height: isActive ? height : 0,
                }}
            >
                <div ref={bodyRef}>{body}</div>
            </div>
        </div>
    );
};

export default Accordion;
